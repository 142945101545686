import { connect } from 'react-redux';
import { defaultMemoize } from 'reselect';
import { ICommonApiParams } from '../../api';
import { INote } from '../../api/INotesResponse';
import Notes from '../../components/Notes/Notes';
import * as notesActions from '../../reducks/notes/actions';
import { getFilteredNotes, isOwner } from '../../reducks/notes/selectors';
import { RootState } from '../../reducks/rootReducer';

const boundGetNotes = defaultMemoize(
  (
    commonApiParams: ICommonApiParams,
    shareKey: string,
    boundAction: typeof notesActions.getNotes
  ) => () =>
    boundAction({
      ...commonApiParams,
      shareKey,
    })
);

const boundDeleteNote = defaultMemoize(
  (
    commonApiParams: ICommonApiParams,
    boundAction: typeof notesActions.deleteNote
  ) => (id: INote['id']) => boundAction(id, commonApiParams)
);

const boundCreateNote = defaultMemoize(
  (
    commonApiParams: ICommonApiParams,
    boundAction: typeof notesActions.createNote
  ) => (text: INote['text'], type: INote['type']) =>
    boundAction(text, type, commonApiParams)
);

const boundDenyNote = defaultMemoize(
  (
    commonApiParams: ICommonApiParams,
    boundAction: typeof notesActions.denyNote
  ) => (id: INote['id']) => boundAction(id, commonApiParams)
);

const boundEditNote = defaultMemoize(
  (
    commonApiParams: ICommonApiParams,
    boundAction: typeof notesActions.editNote
  ) => (id: INote['id'], text: INote['text'], type: INote['type']) =>
    boundAction(id, text, type, commonApiParams)
);

const boundAcceptNote = defaultMemoize(
  (
    commonApiParams: ICommonApiParams,
    boundAction: typeof notesActions.acceptNote
  ) => (id: INote['id']) => boundAction(id, commonApiParams)
);

const mapStateToProps = (state: RootState, props) => ({
  notes: getFilteredNotes(state, props),
  isOwner: isOwner(state, props),
});

const mapDispatchToProps = { ...notesActions };
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    authToken,
    baseUrl,
    jwtToken,
    ivsToken,
    origin,
    product,
    referenceKey,
    shareKey,
    userKey,
  } = ownProps;
  const commonApiParams = {
    authToken,
    baseUrl,
    ivsToken,
    product,
    referenceKey,
    userKey,
    jwtToken,
    origin,
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    createNote: boundCreateNote(commonApiParams, dispatchProps.createNote),
    deleteNote: boundDeleteNote(commonApiParams, dispatchProps.deleteNote),
    editNote: boundEditNote(commonApiParams, dispatchProps.editNote),
    getNotes: boundGetNotes(commonApiParams, shareKey, dispatchProps.getNotes),
    denyNote: boundDenyNote(commonApiParams, dispatchProps.denyNote),
    acceptNote: boundAcceptNote(commonApiParams, dispatchProps.acceptNote),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Notes);
