export const formatToHoursMinutesSeconds = (ms: number | string) => {
  const seconds = Number(ms) / 1000;
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);
  // give padding if needed
  const hh = `0${h}:`.slice(-3);
  const mm = `0${m}:`.slice(-3);
  const ss = `0${s}`.slice(-2);

  const HH = h > 0 ? hh : '';

  return `${HH}${mm}${ss}`;
};
