import clsx from 'clsx';
import { EditorState } from 'draft-js';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { TranslationContext } from '../../context/TranslationsContext';
import { hasEntity } from '../../utitlity/draftUtility';
import AddLinkForm from './formButton';

interface IProps {
  ownTheme: {};
  className?: string;
  placeholder: string;
  theme: {
    button: string;
    active: string;
    buttonWrapper: string;
  };
  store: {
    getEditorState(): EditorState;
  };
  onOverrideContent(content: any);
  onRemoveLinkAtSelection();
  onToolSelected(event: { name: string; enabled: boolean });
}

export default class LinkButton extends Component<IProps> {
  static contextType = TranslationContext;

  onMouseDown = (event) => {
    event.preventDefault();
  };

  onAddLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      ownTheme,
      placeholder,
      onOverrideContent,
      onToolSelected,
    } = this.props;
    onToolSelected({
      name: 'LINK',
      enabled: true,
    });
    const content = (props) => (
      <AddLinkForm {...props} placeholder={placeholder} theme={ownTheme} />
    );
    onOverrideContent(content);
  };
  onRemoveLink = () => {
    const { onToolSelected, onRemoveLinkAtSelection } = this.props;
    onRemoveLinkAtSelection();
    onToolSelected({
      name: 'LINK',
      enabled: false,
    });
  };
  render() {
    const { theme } = this.props;
    const hasLinkSelected = hasEntity(
      this.props.store.getEditorState(),
      'LINK'
    );
    const className = hasLinkSelected
      ? clsx(theme.button, theme.active)
      : theme.button;

    return (
      <div
        className={`${theme.buttonWrapper} ${this.props.className}`}
        onMouseDown={this.onMouseDown}
      >
        <button
          className={className}
          onClick={hasLinkSelected ? this.onRemoveLink : this.onAddLinkClick}
          type="button"
          data-tip={this.context.link}
          data-for="linkToolTip"
          data-effect="solid"
        >
          <svg
            height="20"
            viewBox="0 -2 24 26"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
          </svg>
        </button>
        <ReactTooltip id="linkToolTip" />
      </div>
    );
  }
}
