export const translations = {
  de: {
    notePlaceHolderText: 'Tippen, um Notiz hinzuzufügen',
    noteError: 'Zeit für eine neue Notiz. Sie haben das Zeichenlimit erreicht.',
    spcialCharacterErrorMsg:
      'Ihre Notiz enthält ungültige Zeichen. Löschen Sie vor dem Speichern erst die markierten Zeichen.',
    noteLimitError:
      'You have exceeded the maximum number of notes. You must remove notes in order to continue taking notes.',
    bold: 'Fett',
    italic: 'Kursiv',
    orderList: 'Sortierte Liste',
    strikethrough: 'Durchgestrichen',
    unOrderList: 'Nicht sortierte Liste',
    link: 'Link',
    highlight: 'Highlight',
    actionItem: 'Offener Punkt',
    decision: 'Entscheidung',
    showMore: 'Mehr einblenden',
  },
  en: {
    notePlaceHolderText: 'To add a note, start typing',
    noteError: 'Time for a new note. This one hit the character limit.',
    spcialCharacterErrorMsg:
      'Your note contains invalid characters. To save your note, delete the highlighted characters first.',
    noteLimitError:
      'You have exceeded the maximum number of notes. You must remove notes in order to continue taking notes.',
    bold: 'Bold',
    italic: 'Italic',
    orderList: 'Order List',
    strikethrough: 'Strikethrough',
    unOrderList: 'Un Order List',
    link: 'Link',
    highlight: 'Highlight',
    actionItem: 'Action Item',
    decision: 'Decision',
    showMore: 'Show More',
  },
  es: {
    notePlaceHolderText: 'Empiece a escribir para añadir una nota',
    noteError:
      'Cree una nota nueva, ya que esta ha alcanzado el límite de caracteres.',
    spcialCharacterErrorMsg:
      'Su nota contiene caracteres no válidos. Para guardarla, elimine los caracteres resaltados.',
    bold: 'Negrita',
    italic: 'Cursiva',
    orderList: 'Lista ordenada',
    strikethrough: 'Tachado',
    unOrderList: 'Lista desordenada',
    link: 'Enlace',
    highlight: 'Resaltado',
    actionItem: 'Tarea pendiente',
    decision: 'Decisión',
    showMore: 'Mostrar más',
  },
  fr: {
    notePlaceHolderText: 'Pour ajouter une note, commencez à taper',
    noteError:
      'C’est le moment de créer une autre note. Celle-ci a atteint la limite du nombre de caractères.',
    spcialCharacterErrorMsg:
      'Votre note contient des caractères interdits. Pour enregistrer votre note, supprimez d’abord les caractères mis en évidence.',
    noteLimitError:
      'You have exceeded the maximum number of notes. You must remove notes in order to continue taking notes.',
    bold: 'Gras',
    italic: 'Italique',
    orderList: 'Liste numérotée',
    strikethrough: 'Biffure',
    unOrderList: 'Liste non numérotée',
    link: 'Lien',
    highlight: 'Surlignement',
    actionItem: 'Point d’action',
    decision: 'Décision',
    showMore: 'Afficher plus',
  },
  it: {
    notePlaceHolderText: 'Per aggiungere una nota, inizia a digitarla',
    noteError:
      'Crea una nuova nota. Questa ha raggiunto il limite di caratteri.',
    spcialCharacterErrorMsg:
      'La nota contiene caratteri non validi. Per salvare la nota, elimina prima i caratteri evidenziati.',
    noteLimitError:
      'You have exceeded the maximum number of notes. You must remove notes in order to continue taking notes.',
    bold: 'Grassetto',
    italic: 'Corsivo',
    orderList: 'Elenco numerato',
    strikethrough: 'Barrato',
    unOrderList: 'Elenco puntato',
    link: 'Collegamento',
    highlight: 'Evidenziato',
    actionItem: 'Attività',
    decision: 'Decisione',
    showMore: 'Mostra altro',
  },
  ja: {
    notePlaceHolderText: 'メモを追加するには、入力します。',
    noteError: '新しいメモを作成してください。文字数制限に達しました。',
    spcialCharacterErrorMsg:
      'メモに無効な文字が含まれています。メモを保存するには、ハイライトされた文字を削除してください。',
    noteLimitError:
      'You have exceeded the maximum number of notes. You must remove notes in order to continue taking notes.',
    bold: '太字',
    italic: 'イタリック',
    orderList: '番号付きリスト',
    strikethrough: '取り消し線',
    unOrderList: '箇条書きリスト',
    link: 'リンク',
    highlight: 'ハイライト',
    actionItem: 'アクション項目',
    decision: '決定',
    showMore: '詳細表示',
  },
  ko: {
    notePlaceHolderText: '입력하여 메모 추가',
    noteError: '새 메모를 작성할 시간입니다. 글자 수 제한에 도달했습니다.',
    spcialCharacterErrorMsg:
      '메모에 유효하지 않은 문자가 있습니다. 메모를 저장하려면 강조 표시된 문자를 먼저 삭제하십시오.',
    noteLimitError:
      'You have exceeded the maximum number of notes. You must remove notes in order to continue taking notes.',
    bold: '굵게',
    italic: '기울임꼴',
    orderList: '번호 매기기',
    strikethrough: '취소선',
    unOrderList: '글머리 기호',
    link: '링크',
    highlight: '강조 표시',
    actionItem: '작업 항목',
    decision: '결정',
    showMore: '자세히 표시',
  },
  pt: {
    notePlaceHolderText: 'Para adicionar uma anotação, comece a digitar',
    noteError:
      'Crie uma nova anotação, pois esta atingiu o limite de caracteres.',
    spcialCharacterErrorMsg:
      'Sua anotação contém caracteres inválidos. Para salvá-la, primeiro exclua os caracteres destacados.',
    noteLimitError:
      'You have exceeded the maximum number of notes. You must remove notes in order to continue taking notes.',
    bold: 'Negrito',
    italic: 'Itálico',
    orderList: 'Lista numerada',
    strikethrough: 'Tachado',
    unOrderList: 'Lista não numerada',
    link: 'Link',
    highlight: 'Realce',
    actionItem: 'Item de ação',
    decision: 'Decisão',
    showMore: 'Mostrar mais',
  },
  zh: {
    notePlaceHolderText: '要添加备注，请开始输入',
    noteError: '请做新的备注。此备注已达字符上限。',
    spcialCharacterErrorMsg:
      '您的备注包含无效字符。若要保存备注，请先删除突出显示的字符。',
    noteLimitError:
      'You have exceeded the maximum number of notes. You must remove notes in order to continue taking notes.',
    bold: '加粗',
    italic: '斜体',
    orderList: '排序列表',
    strikethrough: '删除线',
    unOrderList: '无序列表',
    link: '链接',
    highlight: '突出显示',
    actionItem: '待办事项',
    decision: '决策',
    showMore: '显示更多',
  },
};
