import React from 'react';
import ReactTooltip from 'react-tooltip';
import { TranslationContext } from '../../../context/TranslationsContext';
import createInlineStyleButton from '../../HOC/createInlineButtonComponent';
import styles from './styles/button.scss';

class Strikethrough extends React.Component {
  static contextType = TranslationContext;

  render() {
    return (
      <>
        <span
          className={styles['strike-icon-container']}
          data-tip={this.context.strikethrough}
          data-offset="{'top': 10}"
          data-for="strikeOverlay"
          data-effect="solid"
        >
          S
        </span>
        <ReactTooltip id="strikeOverlay" />
      </>
    );
  }
}
export default createInlineStyleButton('STRIKETHROUGH', <Strikethrough />);
