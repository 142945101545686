import { Spinner } from '@getgo/chameleon-react';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { INote } from '../../../api/INotesResponse';
import { isNotesValid } from '../../../utils/inputValidation';
import bookmark from '../assets/bookmark.svg';
import styles from './NoteInput.scss';
import NoteInputButton from './NoteInputButton/NoteInputButton';
import { NoteInputError } from './NoteInputError/NoteInputError';

export interface INoteInputProps {
  clearSubmitError?: () => void; // Required in create mode
  initialText?: string;
  initialType?: INote['type'];
  mode: 'edit' | 'create';
  onClickOutside?: () => void;
  submit?: (text: INote['text'], type: INote['type']) => void;
  submitError?: boolean; // Required in create mode
  submitPending: boolean;
  origin: string;
}

interface INoteInputState {
  text: INote['text'];
  type: INote['type'];
  noteValid: boolean;
}

const typeText: { [key in INote['type']]: string } = {
  actionitem: 'action item',
  highlight: 'highlight',
  note: 'note',
};

const noteLimit = 2048;

export default class NoteInput extends PureComponent<
  INoteInputProps,
  INoteInputState
> {
  static defaultProps = {
    initialText: '',
  };

  container = React.createRef<HTMLDivElement>();
  textArea = React.createRef<HTMLTextAreaElement>();
  clickedNoteInput: boolean;

  state = {
    text: this.props.initialText || '',
    type: this.props.initialType || 'note',
    noteValid: true,
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  onKeyUp = (e) => {
    if (e.keyCode === 13 && this.state.noteValid) {
      this.props.submit(this.state.text, this.state.type);
    }
  };

  onChange = (e) => {
    const noNewLineNote = e.target.value.replace(/\r?\n|\r/g, '');
    this.setState({ text: noNewLineNote.slice(0, noteLimit) });
  };

  // A bookmark is a note with a single space
  onAddBookmark = () => {
    this.props.submit('', this.state.type);
  };

  changeType = (e) => {
    this.setState({ type: e.currentTarget.value });
    this.textArea.current.focus();
  };

  adjustTextArea = () => {
    const textArea = this.textArea.current;
    if (this.textArea.current) {
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 4 + 'px';
    }
  };

  didClickNoteInput = (e) => {
    if (this.container.current.contains(e.target)) {
      this.clickedNoteInput = true;
    }
    if (e.target === this.textArea.current) {
      this.clickedNoteInput = true;
    }
  };

  unfocusNoteInput = () => {
    // Check false explicitly as it can be undefined if component rendered during mouse up
    if (this.clickedNoteInput === false && this.props.mode === 'edit') {
      this.props.onClickOutside();
    }

    this.clickedNoteInput = false;
  };

  componentDidUpdate(prevProps) {
    const noteValid = isNotesValid(this.state.text);
    if (!noteValid && this.state.noteValid) {
      this.setState({ noteValid });
    } else if (noteValid && !this.state.noteValid) {
      this.setState({ noteValid });
    }

    if (this.props.mode === 'create') {
      if (prevProps.submitPending && !this.props.submitPending) {
        if (this.props.submitError) {
          this.props.clearSubmitError();
        } else {
          this.setState({ text: '' });
          this.textArea.current.focus();
        }
      }
    }

    this.adjustTextArea();
  }

  componentDidMount() {
    this.adjustTextArea();
    document.addEventListener('mousedown', this.didClickNoteInput);
    document.addEventListener('mouseup', this.unfocusNoteInput);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.didClickNoteInput);
    document.removeEventListener('mouseup', this.unfocusNoteInput);
  }

  render() {
    const { text, type, noteValid } = this.state;
    const bookmarkPlaceholderText =
      this.props.origin === 'insession' ? 'or a bookmark' : '';
    const placeholderText =
      this.props.mode === 'create'
        ? `Add a ${typeText[type]} ${bookmarkPlaceholderText}`
        : `Add a ${typeText[type]}`;

    return (
      <div ref={this.container} className={styles.noteInput}>
        {!noteValid && <NoteInputError className={styles.inputError} />}
        {this.props.submitPending && <Spinner className={styles.spinner} />}
        <textarea
          className={classnames(
            styles.createInput,
            styles[type],
            !noteValid && styles.noteInvalid
          )}
          disabled={this.props.submitPending}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.onKeyUp}
          placeholder={placeholderText}
          onBlur={(e) => e.stopPropagation()}
          rows={1}
          ref={this.textArea}
          value={text}
        />
        {!this.state.text.trim().length &&
          !this.props.submitPending &&
          this.props.mode === 'create' &&
          this.props.origin === 'insession' && (
            <button className={styles.bookmark} onClick={this.onAddBookmark}>
              <img src={bookmark} />
              <span>+</span>
            </button>
          )}

        {/* TODO: Make this input button combination a separate component */}
        <div className={styles.buttons}>
          <NoteInputButton
            onClick={this.changeType}
            type="note"
            active={type === 'note'}
          />
          <NoteInputButton
            onClick={this.changeType}
            type="highlight"
            active={type === 'highlight'}
          />
          <NoteInputButton
            onClick={this.changeType}
            type="actionitem"
            active={type === 'actionitem'}
          />
        </div>
      </div>
    );
  }
}
