import { EditorState } from 'draft-js';
import React from 'react';
import { removeLinkAtSelection } from '../../utitlity/draftUtility';
import styles from './form.scss';
import LinkButton from './linkButton';
import LinkComponent from './linkComponent';
import { linkStrategy } from './strategy';

interface IConfig {
  theme?: any;
  Link?: any;
  linkTarget?: string;
  placeholder?: string;
  className?: string;
}

const addLinkPlugin = (config: IConfig) => {
  const defaultTheme = {
    input: styles.toolbar_input_form,
    link: styles.link_tag,
    inputInvalid: styles.invalid_url,
  };
  const { theme = defaultTheme, placeholder, Link, linkTarget } = config;

  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
    editorRef: undefined,
  };
  const DecoratedDefaultLink = (props) => (
    <LinkComponent {...props} className={theme.link} target={linkTarget} />
  );
  const DecoratedLinkButton = (props) => (
    <LinkButton
      {...props}
      ownTheme={theme}
      store={store}
      className={props.className}
      placeholder={placeholder}
      onRemoveLinkAtSelection={() =>
        store.setEditorState(removeLinkAtSelection(store.getEditorState()))
      }
    />
  );

  return {
    initialize: ({ getEditorState, setEditorState, getEditorRef }) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
      store.editorRef = getEditorRef();
    },
    onChange: (
      editorState: EditorState,
      { getEditorState, setEditorState, getEditorRef }
    ) => {
      // store.updateItem('selection', editorState.getSelection());
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
      store.editorRef = getEditorRef();
      return editorState;
    },
    decorators: [
      {
        strategy: linkStrategy,
        component: Link || DecoratedDefaultLink,
      },
    ],
    LinkButton: DecoratedLinkButton,
  };
};

const linkPlugin = addLinkPlugin({});

export default linkPlugin;
