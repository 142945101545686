const createStore = (initialState) => {
  let state = initialState || {};
  const listeners = {};

  const subscribeToItem = (key: string | number, callback: any) => {
    listeners[key] = listeners[key] || [];
    listeners[key].push(callback);
  };

  const unsubscribeFromItem = (key: string | number, callback: any) => {
    listeners[key] = listeners[key].filter(
      (listener: any) => listener !== callback
    );
  };

  const updateItem = (key: string | number, item: any) => {
    state = {
      ...state,
      [key]: item,
    };
    if (listeners[key]) {
      listeners[key].forEach((listener: (arg0: any) => any) =>
        listener(state[key])
      );
    }
  };

  const getItem = (key: string | number) => state[key];

  return {
    subscribeToItem,
    unsubscribeFromItem,
    updateItem,
    getItem,
  };
};

export default createStore;
