import clsx from 'clsx';
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js';
import React from 'react';
import { HASHTAG } from '../../constants';
import { getCurrentBlock } from '../../utitlity/draftUtility';

export default (style: string, children: any) => (props: {
  theme: any;
  getEditorState: any;
  setEditorState: any;
  onToolSelected: any;
  className?: string;
}): any => {
  const { theme, getEditorState, setEditorState, onToolSelected } = props;
  const hashTag = ` #${style} `;
  const hashTagInfo = (editorState: EditorState) => {
    const contentBlock = getCurrentBlock(editorState);
    const contentState = editorState.getCurrentContent();
    let startIndex = null;
    let endIndex = null;
    let currentHashTag = null;

    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (
          entityKey !== null &&
          contentState.getEntity(entityKey) &&
          contentState.getEntity(entityKey).getData()
        ) {
          currentHashTag = contentState.getEntity(entityKey).getData().type;
        }

        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === HASHTAG
        );
      },
      (start, end) => {
        startIndex = start;
        endIndex = end;
      }
    );

    return {
      isAnyHasTag: startIndex !== null ? true : false,
      active: currentHashTag && currentHashTag.indexOf(hashTag) > -1,
      anchorOffset: startIndex || 0,
      focusOffset: endIndex || 0,
      activehasTag: currentHashTag,
    };
  };

  const insertHashTag = (
    editorState: EditorState,
    currentContent: ContentState,
    currentSelection: SelectionState,
    hash: string
  ) => {
    const contentStateWithEnity = currentContent.createEntity(
      HASHTAG,
      'IMMUTABLE',
      { type: hash }
    );
    const entityKey = contentStateWithEnity.getLastCreatedEntityKey();
    const newContent = Modifier.insertText(
      contentStateWithEnity,
      currentSelection,
      hash,
      undefined,
      entityKey
    );
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    );
    setEditorState(
      EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
    );

    onToolSelected({
      name: style,
      enabled: true,
    });
  };
  const removehasTag = (
    editorState: EditorState,
    currentContent: ContentState,
    currentSelection: SelectionState,
    hashTagOffset: {
      isAnyHasTag?: boolean;
      active?: boolean;
      anchorOffset: any;
      focusOffset: any;
      activehasTag?: string;
    }
  ) => {
    const newSelection = currentSelection.merge({
      anchorOffset: hashTagOffset.anchorOffset,
      focusOffset: hashTagOffset.focusOffset,
    });
    const newContent = Modifier.removeRange(
      currentContent,
      newSelection,
      'forward'
    );
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'delete-character'
    );
    setEditorState(
      EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
    );
    onToolSelected({
      name: style,
      enabled: false,
    });
  };
  // tslint:disable-next-line: no-shadowed-variable
  const replaceTag = (
    editorState: EditorState,
    currentContent: ContentState,
    currentSelection: SelectionState,
    hashTagOffset: {
      isAnyHasTag?: boolean;
      active?: boolean;
      anchorOffset: any;
      focusOffset: any;
      activehasTag?: string;
    },
    hash: string
  ) => {
    const newSelection = currentSelection.merge({
      anchorOffset: hashTagOffset.anchorOffset,
      focusOffset: hashTagOffset.focusOffset,
    });
    const contentStateWithEnity = currentContent.createEntity(
      HASHTAG,
      'IMMUTABLE',
      { type: hash }
    );
    const entityKey = contentStateWithEnity.getLastCreatedEntityKey();
    const newContent = Modifier.replaceText(
      contentStateWithEnity,
      newSelection,
      hash,
      undefined,
      entityKey
    );
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    );
    setEditorState(
      EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
    );
  };
  const toggleStyle = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const editorState = getEditorState && (getEditorState() as EditorState);
    const currentContent = editorState.getCurrentContent() as ContentState;
    const currentSelection = editorState.getSelection() as SelectionState;
    const tagInfo = hashTagInfo(editorState);

    if (currentSelection.isCollapsed()) {
      if (!tagInfo.isAnyHasTag) {
        insertHashTag(editorState, currentContent, currentSelection, hashTag);
      } else if (tagInfo.isAnyHasTag && tagInfo.activehasTag !== hashTag) {
        replaceTag(
          editorState,
          currentContent,
          currentSelection,
          tagInfo,
          hashTag
        );
      } else {
        removehasTag(editorState, currentContent, currentSelection, tagInfo);
      }
    }
  };

  const preventBubblingUp = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const styleIsActive = () =>
    getEditorState && hashTagInfo(getEditorState()).active;
  const className = styleIsActive()
    ? clsx(theme.button, theme.active)
    : theme.button;

  return (
    <div
      className={`${theme.buttonWrapper} ${props.className}`}
      onMouseDown={preventBubblingUp}
    >
      <button className={className} onClick={toggleStyle} type="button">
        {children}
      </button>
    </div>
  );
};
