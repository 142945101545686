import scroll from 'smoothscroll';

const scrollableParent = (el: HTMLElement) => {
  if (!el) {
    return el;
  }

  const overflowY = getComputedStyle(el).overflowY;
  if (overflowY === 'auto' || overflowY === 'scroll') {
    return el;
  }

  const parentElement = scrollableParent(el.parentElement);
  return parentElement;
};

export const scrollToBottomElement = (el: HTMLElement) => {
  const parent = scrollableParent(el);
  if (parent) {
    scroll(parent && parent.scrollHeight, undefined, undefined, parent);
  }
};
