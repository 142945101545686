import clsx from 'clsx';
import React from 'react';
import { setInlineStyle } from '../../utitlity/draftUtility';

export default (style: string, children: any) => (props): any => {
  const { theme, setEditorState, getEditorState, onToolSelected } = props;

  const toggleStyle = (event: { preventDefault: () => void }) => {
    const styleApplied = styleIsActive();
    onToolSelected({
      name: style,
      enabled: !styleApplied,
    });
    event.preventDefault();

    setEditorState(setInlineStyle(getEditorState(), style));
  };

  const preventBubblingUp = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const styleIsActive = () =>
    getEditorState &&
    getEditorState()
      .getCurrentInlineStyle()
      .has(style);
  const className = styleIsActive()
    ? clsx(theme.button, theme.active)
    : theme.button;

  return (
    <div
      className={`${theme.buttonWrapper} ${props.className}`}
      onMouseDown={preventBubblingUp}
    >
      <button className={className} onClick={toggleStyle} type="button">
        {children}
      </button>
    </div>
  );
};
