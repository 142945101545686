import {
  ContentState,
  convertToRaw,
  genKey,
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from 'draft-js';
import { INote } from '../APIs/INotesResponse';
import { HASHTAG } from '../constants';
import { INoteObjectType } from '../wrappers/InSessionEditorWrapper';

interface IObjectType {
  [key: string]: any;
}
interface IRawDraftContentBlockWithNotePointer extends RawDraftContentBlock {
  isNoteStart?: string;
}
interface IFormatedEntity {
  data: string;
  offset: number;
  length: number;
  style: string;
}

export const draftStateToBackendState = (
  rawState: RawDraftContentState,
  noteBlocks: RawDraftContentBlock[]
): INoteObjectType => {
  const entityMap = rawState.entityMap;
  let collectiveText = '';
  const blockData = noteBlocks;
  let flatternStyles = [];
  let prefixOffset = 0;
  let type;
  const multiNoteType = [];
  const supportedInlineStyle = ['bold', 'italic', 'strikethrough', 'link'];
  const blockNameMap = {
    unstyled: 'BLOCK',
    'unordered-list-item': 'UNORDERED_LIST',
    'ordered-list-item': 'ORDERED_LIST',
  };

  const updateOffset = (offset: number, styles: RawDraftInlineStyleRange[]) => {
    const filterNotSupportedStyles = styles.filter(
      (styleObj: { style: string }) =>
        styleObj.style &&
        supportedInlineStyle.indexOf(styleObj.style.toLowerCase()) > -1
    );
    const offsetUpdatedStyles = filterNotSupportedStyles.map(
      (style: { offset: number }) => {
        style.offset = style.offset + prefixOffset;
        return style;
      }
    );
    return offsetUpdatedStyles;
  };

  const addMultiType = (hashtagType: string) => {
    const trimedType = hashtagType.trim();
    const removeHash = trimedType.replace('#', '');
    multiNoteType.push(removeHash);
  };

  const updateEnityOffsetAndFlatternStructure = (
    offset: number,
    entities: RawDraftEntityRange[]
  ) => {
    const flatterenedEntities = entities.map((entity: RawDraftEntityRange) => {
      const rawEntity = entity as RawDraftEntityRange;
      const formatedEntity: Partial<IFormatedEntity> = {};
      const dataObj = entityMap[rawEntity.key] && entityMap[rawEntity.key].data;
      const linkUrl = dataObj && dataObj.url ? dataObj.url : '';
      const hashType = dataObj && dataObj.type ? dataObj.type : '';

      if (hashType !== '') {
        addMultiType(hashType);
      }
      formatedEntity.offset = rawEntity.offset + prefixOffset;
      formatedEntity.data = linkUrl || hashType;
      formatedEntity.length = rawEntity.length;
      formatedEntity.style = 'LINK';
      return formatedEntity;
    });
    return flatterenedEntities;
  };
  const filteredBlockData = blockData.filter(
    (blockStyles) => Object.keys(blockNameMap).indexOf(blockStyles.type) > -1
  );
  const blockAndListingMap: INote['notesBlocks'] = filteredBlockData.map(
    (blockStyles: RawDraftContentBlock, index) => {
      const lastPrefixOffset =
        index === 0 ? 0 : blockData[index - 1].text.length;
      prefixOffset += lastPrefixOffset;
      flatternStyles = [
        ...flatternStyles,
        ...updateOffset(prefixOffset, blockStyles.inlineStyleRanges),
        ...updateEnityOffsetAndFlatternStructure(
          prefixOffset,
          blockStyles.entityRanges
        ),
      ];
      collectiveText = `${collectiveText}${blockStyles.text}`;
      const blockTypeInfo = {
        style: blockNameMap[blockStyles.type],
        offset: prefixOffset,
        length: blockStyles.text.length,
        order: blockStyles.depth,
        depth: blockStyles.depth,
        id: blockStyles.depth,
      };
      return blockTypeInfo;
    }
  );
  if (multiNoteType.length === 0) {
    multiNoteType.push('note');
    type = 'note';
  }
  return {
    text: collectiveText,
    notesStyles: flatternStyles,
    notesBlocks: blockAndListingMap,
    multiNoteType,
    type,
  };
};

export const getRawContentStateWithNotePointer = (
  contentState: ContentState,
  newNoteMap: IObjectType
) => {
  const rawContentState = convertToRaw(contentState);
  const blockList: IRawDraftContentBlockWithNotePointer[] =
    rawContentState.blocks;
  blockList.forEach((block) => {
    block.isNoteStart = newNoteMap[block.key];
    return block;
  });
  return rawContentState;
};

// separate both finding start and end node so they can be used independently
export const getFirstAndLastBlockKeys = (
  blocks: IRawDraftContentBlockWithNotePointer[],
  lastBlockKey: string
) => {
  if (lastBlockKey === null) {
    return [null, null];
  }
  let index = 0;
  let loop = true;
  let start = null;
  let end = null;
  const afterStart = [];
  const beforeEnd = [];
  while (loop && index < blocks.length) {
    if (blocks[index].key === lastBlockKey) {
      if (blocks[index].isNoteStart) {
        start = blocks[index].key;
      }
      loop = false;
      break;
    }
    index++;
  }
  let backward = index - 1;
  let forward = index + 1;

  while (start === null && backward >= 0) {
    if (blocks[backward].isNoteStart) {
      start = blocks[backward].key;
    } else {
      afterStart.push(blocks[backward].key);
    }
    backward--;
  }

  while (end === null && forward < blocks.length) {
    if (blocks[forward].isNoteStart) {
      end = blocks[forward - 1].key;
    } else {
      beforeEnd.push(blocks[forward].key);
    }
    forward++;
  }
  if (!end) {
    const lastBlock = blocks[forward - 1];
    end = lastBlock ? lastBlock.key : null;
  }

  return [start, end];
};

export const getCurrentNotesBlocks = (
  contentState: ContentState,
  noteStartKeyMap,
  currentBlockKey: string
) => {
  const rawContentState = convertToRaw(contentState);
  const contentStateWithNoteMarker = getRawContentStateWithNotePointer(
    contentState,
    noteStartKeyMap
  );
  const [startKey, endKey] = getFirstAndLastBlockKeys(
    contentStateWithNoteMarker.blocks,
    currentBlockKey
  );
  const blocks = rawContentState.blocks;
  let index = 0;
  const notesBlock: RawDraftContentBlock[] = [];
  let startKeyStarted = false;

  while (index < blocks.length) {
    if (blocks[index].key === startKey) {
      notesBlock.push(blocks[index]);
      startKeyStarted = true;
      if (startKey === endKey) {
        break;
      }
      index++;
      continue;
    }

    if (blocks[index].key === endKey) {
      notesBlock.push(blocks[index]);
      break;
    } else if (startKeyStarted) {
      notesBlock.push(blocks[index]);
    }
    index++;
  }

  return {
    startKey,
    endKey,
    notesBlock,
    contentStateWithNoteMarker,
  };
};

export const backendStateToDraftJsFormat = (data: {
  text: string;
  notesBlocks: INote['notesBlocks'];
  notesStyles: INote['notesStyles'];
  type?: string;
  addNotesDividerBlock?: boolean;
}) => {
  const styles = data.notesStyles;
  const notesBlock = data.notesBlocks;
  const blockMapList = [];
  const entityMap = {};
  const hashTagTypes = ['#highlight', '#actionItem', '#decision'];
  const notesDivider = data.addNotesDividerBlock || false;
  const pushEntityToMap = (entityData) => {
    const index = Object.keys(entityMap).length;
    const trimEntityData = entityData.trim();
    const isHashTagtypes = hashTagTypes.find(
      (hashtag) => trimEntityData === hashtag
    );
    const entityObj = {
      data: isHashTagtypes ? { type: entityData } : { url: entityData },
      mutability: isHashTagtypes ? 'IMMUTABLE' : 'MUTABLE',
      type: isHashTagtypes ? HASHTAG : 'LINK',
    };
    entityMap[index] = entityObj;
    return index;
  };
  const blockNameMap = {
    BLOCK: 'unstyled',
    UNORDERED_LIST: 'unordered-list-item',
    ORDERED_LIST: 'ordered-list-item',
  };

  notesBlock &&
    notesBlock.forEach((blockItem) => {
      const obj: IObjectType = {};
      obj.type = blockNameMap[blockItem.style];
      obj.data = {
        start: blockItem.offset,
        end: blockItem.offset + blockItem.length,
      };
      obj.depth = blockItem.order;
      obj.text = data.text.substring(
        blockItem.offset,
        blockItem.offset + blockItem.length
      );
      obj.key = genKey();
      obj.inlineStyleRanges = [];
      obj.entityRanges = [];
      blockMapList.push(obj);
    });

  styles &&
    styles.length &&
    styles.forEach((item) => {
      if (item.style.toLowerCase() === 'link') {
        const blockWithStyle = blockMapList.find(
          (block) =>
            item.offset >= block.data.start && item.offset < block.data.end
        );

        const obj = {
          offset: item.offset - blockWithStyle.data.start,
          length: item.length,
          key: pushEntityToMap(item.data),
        };
        blockWithStyle.entityRanges.push(obj);
      } else {
        const blockWithStyle = blockMapList.find(
          (block) =>
            item.offset >= block.data.start && item.offset < block.data.end
        );
        blockWithStyle.inlineStyleRanges.push({
          ...item,
          offset: item.offset - blockWithStyle.data.start,
        });
      }
    });

  // Old note convertion
  if (data.text.length && blockMapList.length === 0) {
    const obj: IObjectType = {};
    const presentHashtag = hashTagTypes.find(
      (tag) =>
        tag.toLocaleLowerCase().indexOf(data.type.toLocaleLowerCase()) >= 0
    );
    const hashtag = presentHashtag ? ` ${presentHashtag} ` : '';

    obj.type = blockNameMap.BLOCK;
    obj.data = {
      start: 0,
      end: data.text.length,
    };
    obj.depth = 0;
    obj.text = data.text + hashtag;
    obj.key = genKey();
    obj.inlineStyleRanges = [];
    blockMapList.push(obj);
    if (presentHashtag) {
      obj.entityRanges = [
        {
          offset: data.text.length,
          length: hashtag.length,
          key: 0,
        },
      ];
      pushEntityToMap(hashtag);
    }
  }
  if (notesDivider) {
    blockMapList.push({
      type: 'noteDividier',
      data: {
        start: 0,
        end: 0,
      },
      depth: 0,
      text: '',
      key: genKey(),
      inlineStyleRanges: [],
      entityRanges: [],
    });
  }
  const draftJSFormat = {
    blocks: blockMapList,
    entityMap,
  };

  return draftJSFormat;
};

export const mergeNotesToDraftjsState = (
  noteDraftJsList: RawDraftContentState[]
) => {
  const entityMap = {};
  let blocksList = [];
  let mapBlockWithEntityMapIndex = 0;
  const mergeEntityMap = (entitiesObj) => {
    let index = Object.keys(entityMap).length;
    for (const entityObj of Object.entries(entitiesObj)) {
      entityMap[index] = entityObj[1];
      index++;
    }
  };

  noteDraftJsList.forEach(
    (noteRawDraftJSFormat: {
      blocks: RawDraftContentBlock[];
      entityMap: RawDraftContentState['entityMap'];
    }) => {
      blocksList = [...blocksList, ...noteRawDraftJSFormat.blocks];
      mergeEntityMap(noteRawDraftJSFormat.entityMap);
    }
  );
  blocksList.forEach((contentBlock: RawDraftContentBlock) => {
    contentBlock.entityRanges.forEach((entityRange: RawDraftEntityRange) => {
      entityRange.key = `${mapBlockWithEntityMapIndex}`;
      mapBlockWithEntityMapIndex++;
    });
  });
  return {
    blocks: blocksList,
    entityMap,
  };
};
