// import prependHttp from 'prepend-http';
import mailRegex from './mailRegex';
import urlRegex from './urlRegex';

export default {
  isUrl(text) {
    return urlRegex(undefined).test(text);
  },

  isMail(text) {
    return mailRegex().test(text);
  },

  normaliseMail(email) {
    if (email.toLowerCase().startsWith('mailto:')) {
      return email;
    }
    return `mailto:${email}`;
  },

  normalizeUrl(url, options?) {
    if (typeof url !== 'string') {
      return url;
    }
    url = url.trim();

    options = {
      https: true,
      ...options,
    };

    if (/^\.*\/|^(?!localhost)\w+:/.test(url)) {
      return url;
    }

    return url.replace(
      /^(?!(?:\w+:)?\/\/)/,
      options.https ? 'https://' : 'http://'
    );
  },
};
