import React from 'react';
import ReactTooltip from 'react-tooltip';
import hashTagHOC from '../../HOC/hashTagHOC';
import styles from './styles/button.scss';

export default hashTagHOC(
  'decision',
  <>
    <span
      className={styles['decision-icon-container']}
      data-tip={'Decision'}
      data-offset="{'top': 4}"
      data-for="decisionOverlay"
      data-effect="solid"
    >
      !
    </span>
    <ReactTooltip id="decisionOverlay" />
  </>
);
