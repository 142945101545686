import classnames from 'classnames';
import React from 'react';
import { INote } from '../../../api/INotesResponse';
import { formatToHoursMinutesSeconds } from '../../../utils/time';
import Toolbar from '../../Toolbar/Toolbar';
import bookmark from '../assets/bookmark.svg';
import NoteInput from '../NoteInput/NoteInput';
import disc from './assets/disc.svg';
import styles from './Note.scss';

export interface INoteProps {
  acceptError: boolean;
  acceptNote: (id: INote['id']) => void;
  deleteNote: (id: INote['id']) => void;
  deleteError: boolean;
  denyNote: (id: INote['id']) => void;
  editNote: (id: INote['id'], text: INote['text'], type: INote['type']) => void;
  isActive: boolean;
  isOwner: boolean;
  note: INote;
  origin: INote['origin'];
  playNote: (videoTime: INote['videoTime']) => void;
  setActiveNoteId: (id?: INote['id']) => void;
  setActiveEditNoteId: (id?: INote['id']) => void;
  tooltipLocation?: 'top' | 'bottom' | 'left' | 'right';
  editError: boolean;
}

export interface INoteState {
  accepting: boolean;
  deleting: boolean;
  editing: boolean;
  editModeEnabled: boolean;
}

const typeText: Partial<Record<INote['type'], string>> = {
  actionitem: 'Action Item',
  highlight: 'Highlight',
};

export default class Note extends React.PureComponent<INoteProps, INoteState> {
  static defaultProps = {
    tooltipLocation: 'top',
  };

  noteRef = React.createRef<HTMLDivElement>();

  state = {
    accepting: false,
    deleting: false,
    editing: false,
    editModeEnabled: false,
  };

  handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (
      this.props.isActive &&
      this.noteRef.current.contains(e.currentTarget) &&
      !this.state.deleting
    ) {
      this.setState({ deleting: true });
      this.props.deleteNote(this.props.note.id);
    } else if (!this.state.deleting) {
      this.props.setActiveNoteId(this.props.note.id);
    }
  };

  handlePlay = () => {
    this.props.playNote(this.props.note.videoTime);
  };

  toggleEditMode = () => {
    this.setState({ editModeEnabled: !this.state.editModeEnabled });
  };

  clearActiveNote = () => {
    this.props.setActiveNoteId();
  };

  thumbsUp = () => {
    if (!this.state.accepting && !this.state.deleting) {
      this.props.acceptNote(this.props.note.id);
      this.setState({ accepting: true });
    }
  };

  thumbsDown = () => {
    if (!this.state.deleting) {
      this.setState({ deleting: true });
      this.props.denyNote(this.props.note.id);
    }
  };

  submitEdit = (text: INote['text'], type: INote['type']) => {
    if (text === this.props.note.text && type === this.props.note.type) {
      this.setState({ editModeEnabled: false });
    } else {
      this.setState({ editing: true });
      this.props.editNote(this.props.note.id, text, type);
    }
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.acceptError && this.props.acceptError) {
      this.setState({ accepting: false });
    }

    if (!prevProps.deleteError && this.props.deleteError) {
      this.setState({ deleting: false });
    }

    if (prevProps.note !== this.props.note) {
      this.setState({ editing: false, editModeEnabled: false });
    }

    if (prevProps.editError === false && this.props.editError === true) {
      this.setState({ editing: false });
    }
  }

  render() {
    const { isActive, isOwner, note, origin } = this.props;
    const { text, videoTime } = note;
    const isAutogenerated =
      note.origin === 'autogenerated' &&
      !this.state.accepting &&
      note.status !== 'accepted';
    const isBookmark = !note.text.replace(/\s/g, '').length;

    if (!isAutogenerated && this.state.editModeEnabled && isOwner) {
      return (
        <NoteInput
          initialType={note.type}
          submit={this.submitEdit}
          submitPending={this.state.editing}
          onClickOutside={this.toggleEditMode}
          initialText={note.text}
          origin={origin}
          mode="edit"
        />
      );
    }

    let timeComponent;
    if (videoTime !== -1 && typeof videoTime === 'number') {
      timeComponent = (
        <div className={styles.noteTime}>
          {formatToHoursMinutesSeconds(videoTime)}
        </div>
      );
    }

    return (
      <div
        className={classnames(
          styles.note,
          isActive && styles.isActive,
          isAutogenerated && styles.autogeneratedNote
        )}
        ref={this.noteRef}
      >
        <div
          className={classnames(
            styles.outerRow,
            (isOwner || timeComponent) && styles.extraPadding
          )}
        >
          {isAutogenerated && (
            <div className={styles.autogeneratedRow}>
              <span>Keep SmartNote:</span>
              <i
                onClick={this.thumbsUp}
                data-notes-tip={'Approve SmartNote'}
                className={classnames(
                  'togo-icon togo-icon-thumb-up-filled',
                  styles.thumbsUp
                )}
              />
              <i
                onClick={this.thumbsDown}
                data-notes-tip={'Dismiss SmartNote'}
                className={classnames(
                  'togo-icon togo-icon-thumb-up-filled togo-icon-rotate-180',
                  styles.thumbsDown
                )}
              />
            </div>
          )}

          <div className={styles.row}>
            <img className={styles.disc} src={disc} />
            <span
              className={classnames(
                styles.noteText,
                isBookmark && styles.isBookmark
              )}
            >
              {isBookmark ? (
                <img className={styles.bookmarkIcon} src={bookmark} />
              ) : (
                <span className={styles.text}>{text}</span>
              )}

              {typeText[note.type] && (
                <span className={styles[`${note.type}Tag`]}>
                  {typeText[note.type]}
                </span>
              )}
            </span>
          </div>

          {!isAutogenerated && (
            <div className={styles.hover}>
              <Toolbar
                isActive={isActive}
                isOwner={isOwner}
                origin={origin}
                onPlay={this.handlePlay}
                onEdit={this.toggleEditMode}
                onDelete={this.handleDelete}
                onBlur={this.clearActiveNote}
                showPlay={videoTime !== -1}
              />
            </div>
          )}
          {timeComponent}
        </div>
      </div>
    );
  }
}
