import classnames from 'classnames';
import React from 'react';
import { INote } from '../../../../api/INotesResponse';
import actionItemIcon from './assets/action-item.svg';
import activeActionItemIcon from './assets/active-action-item.svg';
import activeHighlightIcon from './assets/active-highlight.svg';
import activeNoteIcon from './assets/active-note.svg';
import highlightIcon from './assets/highlight.svg';
import noteIcon from './assets/note.svg';
import styles from './NoteInputButton.scss';

export interface INoteInputButtonProps {
  active: boolean;
  onClick: (...args: any) => void;
  type: INote['type'];
}

const icons: {
  [key in INote['type']]: { active: string; inactive: string }
} = {
  note: {
    active: activeNoteIcon,
    inactive: noteIcon,
  },
  actionitem: {
    active: activeActionItemIcon,
    inactive: actionItemIcon,
  },
  highlight: {
    active: activeHighlightIcon,
    inactive: highlightIcon,
  },
};

const typeColors: { [key in INote['type']]: string } = {
  actionitem: '#4AA26D',
  highlight: '#EB9342',
  note: '#0079FB',
};

const typeText: { [key in INote['type']]: string } = {
  actionitem: 'Action Item',
  highlight: 'Highlight',
  note: 'Note',
};

const NoteInputButton: React.SFC<INoteInputButtonProps> = (props) => {
  const { active, onClick, type } = props;
  return (
    <button
      className={classnames(styles.button)}
      data-notes-tip={typeText[type]}
      onClick={onClick}
      style={{ backgroundColor: active && typeColors[type] }}
      value={type}
    >
      <img src={active ? icons[type].active : icons[type].inactive} />
    </button>
  );
};

export default NoteInputButton;
