export const HASHTAG = 'HASHTAG';
export const TOOLBARDISABLED = 'ToolbarDisabled';
export const MULTINOTEDELETEDISABLED = 'MultiNoteDeleteDisabled';
export const EXCEEDEDCHARACTERLIMIT = 'ExceededCharacterLimit';
export const SPECIALCHARACTERERROR = 'SpecialCharacterError';
export const NOTECOUNTLIMITEXCEEDED = 'NoteCountLimitExceeded';
export const TOOLUSED = 'ToolUsed';
export const NOTECREATED = 'NoteCreated';
export const NOTEEDIT = 'NoteEdit';
export const NOTEDELETE = 'NoteDelete';
export const MOVETONEXTNOTE = 'MoveToNextNote';
export const ENTERKEY = 'Enter/ReturnKey';
export const ARROWORMOUSECLICK = 'ArrowOrMouseClick';
export const NOTESAPPVISITED = 'NotesAppVisited';
export const NOTECREATEDERROR = 'NoteCreatError';
export const NOTEEDITERROR = 'NoteEditError';
export const NOTEDELETEERROR = 'NoteDeleteError';
export const NOTECOUNTLIMIT = 200;
export const NOTECHARACTERSLIMIT = 2048;
