import { fetchJsonWithAuth } from '../utils/fetch';
import { INote, INotesCreateResponse, INotesResponse } from './INotesResponse';

export interface IIvsResponse {
  token: string;
  email: string;
  status: string;
  expirationTime: string;
}

export interface ICommonApiParams {
  authToken?: string;
  baseUrl: string;
  jwtToken?: string;
  ivsToken?: IIvsResponse;
  origin: INote['origin'];
  product: string;
  referenceKey?: string;
  shareKey?: string;
  userKey?: string;
}

export const getNotes = async (apiParams: ICommonApiParams) => {
  const {
    authToken,
    baseUrl,
    ivsToken,
    jwtToken,
    product,
    referenceKey,
    shareKey,
    userKey,
  } = apiParams;
  const url = shareKey
    ? `${baseUrl}/v1/notes/product/${product}/shareKey/${shareKey}`
    : `${baseUrl}/v1/notes/users/${userKey}/product/${product}/referenceKey/${referenceKey}`;

  const headers = ivsToken ? { 'X-IVS-Token': ivsToken.token } : undefined;

  let notesRes;
  try {
    notesRes = await fetchJsonWithAuth<INotesResponse>(
      url,
      jwtToken,
      authToken,
      { headers }
    );
  } catch (e) {
    if (e.status === 404) {
      return [];
    }

    throw e;
  }

  notesRes.notes.sort((a, b) => a.startEpochTimeStamp - b.startEpochTimeStamp);
  return notesRes.notes;
};

export const deleteNote = async (
  id: INote['id'],
  apiParams: ICommonApiParams
) => {
  const {
    authToken,
    baseUrl,
    product,
    jwtToken,
    referenceKey,
    userKey,
  } = apiParams;

  try {
    await fetchJsonWithAuth(
      `${baseUrl}/v1/notes/users/${userKey}`,
      jwtToken,
      authToken,
      {
        method: 'DELETE',
        body: JSON.stringify({
          product,
          referenceKey,
          noteIds: [id],
        }),
      }
    );
  } catch (e) {
    throw {
      id,
    };
  }

  return id;
};

export const createNote = async (
  text: INote['text'] = '',
  type: INote['type'],
  apiParams: ICommonApiParams
) => {
  const {
    authToken,
    baseUrl,
    origin,
    product,
    jwtToken,
    referenceKey,
    userKey,
  } = apiParams;

  // Empty text or spaces means that a note is a bookmark
  // From the backend, there are currently no fields that represent a bookmark besides text
  const isBookmark = !text.trim().length;
  const res = await fetchJsonWithAuth<INotesCreateResponse>(
    `${baseUrl}/v1/notes/users/${userKey}`,
    jwtToken,
    authToken,
    {
      method: 'POST',
      body: JSON.stringify({
        product,
        referenceKey,
        origin,
        notes: [{ text: isBookmark ? ' ' : text, type }],
      }),
    }
  );

  return res.notes[0].noteItem;
};

export const acceptNote = async (
  id: INote['id'],
  apiParams: ICommonApiParams
) => {
  const {
    authToken,
    baseUrl,
    product,
    jwtToken,
    referenceKey,
    userKey,
  } = apiParams;
  const res = await fetchJsonWithAuth<INotesCreateResponse>(
    `${baseUrl}/v1/notes/users/${userKey}`,
    jwtToken,
    authToken,
    {
      method: 'PUT',
      body: JSON.stringify({
        product,
        referenceKey,
        notes: [{ id, status: 'accepted' }],
      }),
    }
  );

  return res.notes[0].noteItem;
};

export const editNote = async (
  id: INote['id'],
  text: INote['text'],
  type: INote['type'],
  apiParams
) => {
  const {
    authToken,
    baseUrl,
    product,
    jwtToken,
    referenceKey,
    userKey,
  } = apiParams;

  let res;
  try {
    res = await fetchJsonWithAuth<INotesCreateResponse>(
      `${baseUrl}/v1/notes/users/${userKey}`,
      jwtToken,
      authToken,
      {
        method: 'PUT',
        body: JSON.stringify({
          product,
          referenceKey,
          notes: [{ id, text, type }],
        }),
      }
    );
  } catch (e) {
    throw {
      id,
    };
  }

  return res.notes[0].noteItem;
};
