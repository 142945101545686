class MixPanelEvents {
  private props;
  private track;
  constructor(track, props?: { [key: string]: any }) {
    this.props = { ...props };
    this.track = track;
  }
  trackEvents(name: string, eventProps?: { [key: string]: any }) {
    this.track(name, { ...this.props, ...eventProps });
  }
  trackeNoteCreateAndEditEvents(
    name: string,
    id: string,
    styles: any,
    types: string[]
  ) {
    const propsObj = {
      type: [],
    };
    styles.forEach((style) => {
      propsObj[style.style] = true;
    });
    propsObj.type = types;
    this.track(name, { ...this.props, ...propsObj, id });
  }
}

export default MixPanelEvents;
