import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from '../../../styles/toolbar.scss';
import hashTagHOC from '../../HOC/hashTagHOC';

export default hashTagHOC(
  'highlight',
  <>
    <span
      data-tip={'Highlight'}
      data-offset="{'top': 4}"
      data-for="highlightOverlay"
      data-effect="solid"
    >
      <svg width="18" height="14">
        <g
          id="Notes"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Notes---Wide---Phase-2"
            transform="translate(-711.000000, -647.000000)"
            fill="#25282D"
          >
            <g id="Popover" transform="translate(197.000000, 632.000000)">
              <g
                id="highlight"
                className={styles.nestedSvg}
                transform="translate(514.000000, 15.000000)"
              >
                <path
                  d="M7.53052632 9.64526316 L9.76315789 7.41263158 L10.2863158 7.93578947 L14 4.22210526 L9.79263158 0.0147368421 L6.07894737 3.72842105 L6.60210526 4.25157895 L4.37684211 6.49157895 L7.53052632 9.64526316 Z M12.5410526 12.5263158 L0.751578947 12.5263158 C0.346315789 12.5263158 0.0147368421 12.8578947 0.0147368421 13.2631579 C0.0147368421 13.6684211 0.346315789 14 0.751578947 14 L12.5410526 14 C12.9463158 14 13.2778947 13.6684211 13.2778947 13.2631579 C13.2778947 12.8578947 12.9463158 12.5263158 12.5410526 12.5263158 Z M1.48842105 11.7894737 L4.46526316 11.7894737 C4.67157895 11.7894737 4.85578947 11.7084211 4.98842105 11.5684211 L6.47684211 10.08 L3.94210526 7.53789474 L0.965263158 10.5147368 C0.832631579 10.6473684 0.744210526 10.8389474 0.744210526 11.0378947 C0.751578947 11.4578947 1.08315789 11.7894737 1.48842105 11.7894737 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
    <ReactTooltip id="highlightOverlay" />
  </>
);
