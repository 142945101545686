import clsx from 'clsx';
import React from 'react';
import { TranslationContext } from '../../context/TranslationsContext';
import styles from './ErrorComponent.scss';

interface IErrorComponentProps {
  marginTop?: string;
}
class ErrorComponent extends React.Component<IErrorComponentProps> {
  static contextType = TranslationContext;

  render() {
    const marginTop = styles[this.props.marginTop];
    return (
      <div className={clsx(styles.errMsg, marginTop)}>
        {this.context.noteLimitError}
      </div>
    );
  }
}

export default ErrorComponent;
