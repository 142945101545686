import {
  ContentBlock,
  ContentState,
  EditorState,
  genKey,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js';
import { List } from 'immutable';
import { HASHTAG } from '../constants';
import { hashtagStrategy } from '../plugins/definedHashTagPlugin';

export const getCurrentBlock = (editoState: EditorState) => {
  const currentState = editoState.getCurrentContent();
  const currentSelection = editoState.getSelection();
  const focusKey = currentSelection.getFocusKey();
  const contentBlock = currentState.getBlockForKey(focusKey);
  return contentBlock;
};

export const createLinkAtSelection = (
  editorState: EditorState,
  url: string
): EditorState => {
  const contentState = editorState
    .getCurrentContent()
    .createEntity('LINK', 'MUTABLE', { url });
  const entityKey = contentState.getLastCreatedEntityKey();
  const withLink = setInlineStyle(editorState, undefined, entityKey);
  return EditorState.forceSelection(withLink, editorState.getSelection());
};
export const removeLinkAtSelection = (editorState: EditorState) => {
  return setInlineStyle(editorState, undefined, null);
};

export const getCurrentEntityKey = (editorState: EditorState) => {
  const selection = editorState.getSelection() as SelectionState;
  const anchorKey = selection.getAnchorKey();
  const contentState = editorState.getCurrentContent();
  const anchorBlock = contentState.getBlockForKey(anchorKey);
  const offset = selection.getAnchorOffset();
  const index = selection.getIsBackward() ? offset - 1 : offset;
  return anchorBlock.getEntityAt(index);
};
export const getCurrentEntity = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const entityKey = getCurrentEntityKey(editorState);
  return entityKey ? contentState.getEntity(entityKey) : null;
};
export const hasEntity = (editorState: EditorState, entityType) => {
  const entity = getCurrentEntity(editorState);
  return entity && entity.getType() === entityType;
};
export const getCurrentBlockKey = (editorState: EditorState) => {
  const selection = editorState.getSelection() as SelectionState;
  const anchorKey = selection.getAnchorKey();
  return anchorKey;
};
export const insertNewBlock = (
  direction: 'before' | 'after',
  insertBeforeBlockKey: string,
  editorState: EditorState,
  type?: string
): ContentState => {
  const contentState = editorState.getCurrentContent();
  const currentBlock = contentState.getBlockForKey(insertBeforeBlockKey);
  const blockType = type || 'unstyled';

  const blockMap = contentState.getBlockMap();
  // Split the blocks
  const blocksBefore = blockMap.toSeq().takeUntil((v) => {
    return v === currentBlock;
  });
  const blocksAfter = blockMap
    .toSeq()
    .skipUntil((v) => {
      return v === currentBlock;
    })
    .rest();
  const newBlockKey = genKey();
  const newBlocks =
    direction === 'before'
      ? [
          [
            newBlockKey,
            new ContentBlock({
              key: newBlockKey,
              type: blockType,
              text: '',
              characterList: List(),
            }),
          ],
          [currentBlock.getKey(), currentBlock],
        ]
      : [
          [currentBlock.getKey(), currentBlock],
          [
            newBlockKey,
            new ContentBlock({
              key: newBlockKey,
              type: blockType,
              text: '',
              characterList: List(),
            }),
          ],
        ];
  const newBlockMap = blocksBefore
    .concat(newBlocks, blocksAfter)
    .toOrderedMap();
  const newContentState = ContentState.createFromBlockArray(
    newBlockMap.toArray()
  );

  return newContentState;
};

export const getNewContentBlock = (type = 'unstyled') => {
  const key = genKey();
  const blockType = type;
  const newBlock = new ContentBlock({
    key,
    type: blockType,
    text: '',
    characterList: List(),
  });
  return {
    key,
    newBlock,
  };
};

export const removeBlockFromContent = (
  editorState: EditorState,
  key: string
): ContentState => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();
  const newBlockMap = blockMap.remove(key);
  const newContentState: ContentState = ContentState.createFromBlockArray(
    newBlockMap.toArray()
  );
  return newContentState;
};

const toggleInlineStyling = (
  editorState: EditorState,
  style: string,
  entityKey?: string
) => {
  return typeof entityKey !== 'undefined'
    ? RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey)
    : RichUtils.toggleInlineStyle(editorState, style);
};

const getSelectionDetails = (selectionState: SelectionState) => {
  const isBackSelection = selectionState.getIsBackward();
  const selectionKeysNOffset = {
    anchorOffset: isBackSelection
      ? selectionState.getFocusOffset()
      : selectionState.getAnchorOffset(),
    focusOffset: isBackSelection
      ? selectionState.getAnchorOffset()
      : selectionState.getFocusOffset(),
    startKey: selectionState.getStartKey(),
    endKey: selectionState.getEndKey(),
    anchorKey: isBackSelection
      ? selectionState.getFocusKey()
      : selectionState.getAnchorKey(),
    focusKey: isBackSelection
      ? selectionState.getAnchorKey()
      : selectionState.getFocusKey(),
  };

  return selectionKeysNOffset;
};

const isOnlyHashTagSelected = (
  selectionDetails: ReturnType<typeof getSelectionDetails>,
  contentBlock: ContentBlock,
  contentState: ContentState
) => {
  let isHashtagSelected = false;

  if (selectionDetails.startKey === selectionDetails.endKey) {
    hashtagStrategy(
      contentBlock,
      (start, end) => {
        if (
          selectionDetails.anchorOffset >= start &&
          selectionDetails.focusOffset <= end
        ) {
          isHashtagSelected = true;
        }
      },
      contentState
    );
  }

  return isHashtagSelected;
};

const isCurrentStyleNotApplied = (
  contentBlock: ContentBlock,
  offset: number,
  style: string
) => {
  const firstBlockInlineStyles = contentBlock.getInlineStyleAt(offset);
  return !firstBlockInlineStyles.has(style);
};

const isSelectionAtLengthOfContentBlock = (
  contentBlock: ContentBlock,
  offset: number
) => {
  return contentBlock.getText().length === offset;
};

const getHashTagDetailsInBlock = (
  contentBlock,
  currentBlockKey,
  anchorOffset,
  focusOffset,
  contentState
) => {
  let hashTagInfo = null;

  hashtagStrategy(
    contentBlock,
    (start, end) => {
      if (!(focusOffset < start || anchorOffset >= end)) {
        hashTagInfo = {
          key: currentBlockKey,
          anchorOffset: start,
          focusOffset: end,
          entityKey: contentState.getEntity(contentBlock.getEntityAt(start)),
          entityType: contentState
            .getEntity(contentBlock.getEntityAt(start))
            .getType(),
          data: contentState
            .getEntity(contentBlock.getEntityAt(start))
            .getData(),
        };
      }
    },
    contentState
  );

  return hashTagInfo;
};

const getHashTagsListInCurrentSelection = (
  selectionDetails,
  contentState: ContentState
) => {
  let startKeyFound = false;
  const hashTagListFromSelection = [];
  const blockMapList = contentState.getBlockMap().toArray();
  blockMapList.find((contentBlock) => {
    const currentBlockKey = contentBlock.getKey();
    const contentBlockLengthOffset = contentBlock.getText().length - 1;
    let anchorOffset;
    let focusOffset;
    let shouldReturn = false;
    if (currentBlockKey === selectionDetails.startKey) {
      startKeyFound = true;

      if (contentBlock.getKey() === selectionDetails.endKey) {
        anchorOffset = selectionDetails.anchorOffset;
        focusOffset = selectionDetails.focusOffset;
        shouldReturn = true;
      } else {
        anchorOffset = selectionDetails.anchorOffset;
        focusOffset = contentBlockLengthOffset;
      }
    } else if (contentBlock.getKey() === selectionDetails.endKey) {
      anchorOffset = 0;
      focusOffset = selectionDetails.focusOffset;
      shouldReturn = true;
    } else if (startKeyFound) {
      anchorOffset = 0;
      focusOffset = contentBlockLengthOffset;
    }
    const hashtagDetails =
      startKeyFound &&
      getHashTagDetailsInBlock(
        contentBlock,
        currentBlockKey,
        anchorOffset,
        focusOffset,
        contentState
      );

    if (hashtagDetails) {
      hashTagListFromSelection.push(hashtagDetails);
    }

    if (shouldReturn) {
      return true;
    }
  });

  return hashTagListFromSelection;
};

export const skipHashtagSelectionIfStartingFromHashTag = (
  editorState: EditorState,
  contentBlock: ContentBlock,
  contentState: ContentState,
  selectionDetails
) => {
  let isSelectionStartedFromHashTag = false;
  let modifiedEditorState = editorState;
  let hashTagOffset;
  let isEditorStateModified = false;
  hashtagStrategy(
    contentBlock,
    (start, end) => {
      hashTagOffset = end;
      if (
        selectionDetails.anchorOffset >= start &&
        selectionDetails.anchorOffset <= end
      ) {
        isSelectionStartedFromHashTag = true;
      }
    },
    contentState
  );

  if (isSelectionStartedFromHashTag) {
    const selectionState = editorState.getSelection();
    let modifiedSelectionState = selectionState.merge({
      anchorOffset: hashTagOffset,
      focusOffset: selectionDetails.focusOffset,
      anchorKey: selectionDetails.anchorKey,
      focusKey: selectionDetails.focusKey,
      isBackward: false,
    });
    if (
      contentBlock.getText().length === hashTagOffset &&
      selectionDetails.anchorKey !== selectionDetails.focusKey
    ) {
      const nextBlock = contentState.getBlockAfter(contentBlock.getKey());
      modifiedSelectionState = selectionState.merge({
        anchorOffset: 0,
        focusOffset: selectionDetails.focusOffset,
        anchorKey: nextBlock.getKey(),
        focusKey: selectionDetails.focusKey,
        isBackward: false,
      });
    }

    modifiedEditorState = EditorState.forceSelection(
      editorState,
      modifiedSelectionState
    );
    isEditorStateModified = true;
  }

  return {
    skipHashtagSelectionEditorState: modifiedEditorState,
    isEditorStateModified,
  };
};

const handleLinkStyling = (
  editorState: EditorState,
  hashTagListFromSelection,
  selection: SelectionState
) => {
  let modifiedEditorState = editorState;
  let modifiedContent = editorState.getCurrentContent();

  hashTagListFromSelection.map((hashtag) => {
    const hashTagSelection = selection.merge({
      anchorKey: hashtag.key,
      focusKey: hashtag.key,
      anchorOffset: hashtag.anchorOffset,
      focusOffset: hashtag.focusOffset,
      isBackward: false,
    });
    modifiedContent = modifiedEditorState.getCurrentContent();

    modifiedContent = modifiedContent.createEntity(HASHTAG, 'IMMUTABLE', {
      type: hashtag.data.type,
    });
    const currentEnityKey = modifiedContent.getLastCreatedEntityKey();
    modifiedContent = Modifier.applyEntity(
      modifiedContent,
      hashTagSelection,
      currentEnityKey
    );
    modifiedEditorState = EditorState.push(
      modifiedEditorState,
      modifiedContent,
      'apply-entity'
    );
  });

  return modifiedEditorState;
};

const handleInlineStyle = (
  editorState: EditorState,
  firstSelectedContentBlock: ContentBlock,
  hashTagListFromSelection,
  style,
  anchorOffset
) => {
  let modifiedEditorState = editorState;
  const selection = modifiedEditorState.getSelection();
  if (
    isCurrentStyleNotApplied(firstSelectedContentBlock, anchorOffset, style) &&
    !isSelectionAtLengthOfContentBlock(firstSelectedContentBlock, anchorOffset)
  ) {
    // handle other style
    hashTagListFromSelection.map((hashtag) => {
      const hashTagSelection = selection.merge({
        anchorKey: hashtag.key,
        focusKey: hashtag.key,
        anchorOffset: hashtag.anchorOffset,
        focusOffset: hashtag.focusOffset,
        isBackward: false,
      });
      modifiedEditorState = EditorState.forceSelection(
        modifiedEditorState,
        hashTagSelection
      );
      modifiedEditorState = RichUtils.toggleInlineStyle(
        modifiedEditorState,
        style
      );
    });
  }

  return modifiedEditorState;
};

export const setInlineStyle = (
  editorState: EditorState,
  style,
  entityKey?: string
) => {
  const selection = editorState.getSelection();

  if (selection.isCollapsed()) {
    return toggleInlineStyling(editorState, style, entityKey);
  }

  const isLinkStyle = typeof entityKey !== 'undefined';
  const contentState = editorState.getCurrentContent();
  let modifiedEditorState = editorState;
  let selectionDetails = getSelectionDetails(selection);
  let hashtagSkipSelection = false;

  const firstContentBlock = contentState.getBlockForKey(
    selectionDetails.anchorKey
  );

  if (
    isOnlyHashTagSelected(selectionDetails, firstContentBlock, contentState)
  ) {
    return editorState;
  }

  if (!isLinkStyle) {
    const {
      skipHashtagSelectionEditorState,
      isEditorStateModified,
    } = skipHashtagSelectionIfStartingFromHashTag(
      modifiedEditorState,
      firstContentBlock,
      contentState,
      selectionDetails
    );
    modifiedEditorState = skipHashtagSelectionEditorState;
    hashtagSkipSelection = isEditorStateModified;
  }

  selectionDetails = getSelectionDetails(modifiedEditorState.getSelection());
  const hashTagListFromSelection = getHashTagsListInCurrentSelection(
    selectionDetails,
    contentState
  );

  modifiedEditorState = toggleInlineStyling(
    modifiedEditorState,
    style,
    entityKey
  ); // apply inline styling

  modifiedEditorState = isLinkStyle
    ? handleLinkStyling(
        modifiedEditorState,
        hashTagListFromSelection,
        selection
      )
    : handleInlineStyle(
        modifiedEditorState,
        firstContentBlock,
        hashTagListFromSelection,
        style,
        selectionDetails.anchorOffset
      );

  // reselect the original selection.
  if (hashtagSkipSelection) {
    modifiedEditorState = EditorState.forceSelection(
      modifiedEditorState,
      selection.merge({
        anchorOffset: selectionDetails.anchorOffset,
        focusOffset: selectionDetails.focusOffset,
        anchorKey: selectionDetails.anchorKey,
        focusKey: selectionDetails.focusKey,
        isBackward: false,
      })
    );
  } else {
    modifiedEditorState = EditorState.forceSelection(
      modifiedEditorState,
      selection.merge({
        anchorOffset: selection.getAnchorOffset(),
        focusOffset: selection.getFocusOffset(),
        anchorKey: selection.getAnchorKey(),
        focusKey: selection.getFocusKey(),
        isBackward: selection.getIsBackward(),
      })
    );
  }

  return modifiedEditorState;
};

const getEffectedStyledBlocks = (
  contentState: ContentState,
  selectionDetails,
  blockType: string
) => {
  const startBlock = contentState.getBlockForKey(selectionDetails.startKey);
  const blockMapArray = contentState.getBlockMap().toArray();
  const blockStyleApplied = startBlock.getType();
  const contentBlockList = [];
  let startKeyFound = false;

  if (
    blockStyleApplied !== blockType &&
    selectionDetails.startKey !== selectionDetails.endKey
  ) {
    blockMapArray.find((contentBlock) => {
      if (contentBlock.getDepth() > 0) {
        if (selectionDetails.startKey === contentBlock.getKey()) {
          contentBlockList.push(contentBlock);
          startKeyFound = true;
          if (selectionDetails.startKey === selectionDetails.endKey) {
            return true;
          }
        } else if (selectionDetails.endKey === contentBlock.getKey()) {
          contentBlockList.push(contentBlock);
          return true;
        } else if (startKeyFound) {
          contentBlockList.push(contentBlock);
        }
      } else if (selectionDetails.startKey === contentBlock.getKey()) {
        startKeyFound = true;
      }
    });
  }

  return contentBlockList;
};

const setBlockDepth = (
  editorState: EditorState,
  contentBlockList,
  selectionDetails
) => {
  let modifiedEditorState = editorState;
  if (contentBlockList.length > 0) {
    const changedContentBlockList = modifiedEditorState
      .getCurrentContent()
      .getBlockMap()
      .toArray();

    let i = 0;
    let j = 0;

    while (i < contentBlockList.length && j < changedContentBlockList.length) {
      const changedBlockType = changedContentBlockList[j].getType();
      if (
        changedContentBlockList[j].getKey() === contentBlockList[i].getKey() &&
        (changedBlockType === 'ordered-list-item' ||
          changedBlockType === 'unordered-list-item')
      ) {
        const block = contentBlockList[i];
        const alteredContentBlock = new ContentBlock({
          key: block.getKey(),
          text: block.getText(),
          characterList: block.getCharacterList(),
          type: changedBlockType,
          depth: block.getDepth(),
          data: block.getData(),
        });
        changedContentBlockList[j] = alteredContentBlock;
        i++;
      }
      j++;
    }
    const newContentState = ContentState.createFromBlockArray(
      changedContentBlockList
    );
    modifiedEditorState = EditorState.push(
      editorState,
      newContentState,
      'change-block-data'
    );

    modifiedEditorState = EditorState.forceSelection(
      modifiedEditorState,
      modifiedEditorState.getSelection().merge({
        anchorKey: selectionDetails.anchorKey,
        anchorOffset: selectionDetails.anchorOffset,
        focusKey: selectionDetails.focusKey,
        focusOffset: selectionDetails.focusOffset,
        isBackward: selectionDetails.isBackward,
      })
    );
  }
  return modifiedEditorState;
};

export const setBlockStyling = (
  editorState: EditorState,
  blockType: string
): EditorState => {
  const contentState: ContentState = editorState.getCurrentContent();
  let modifiedEditorState = editorState;

  const selectionState = editorState.getSelection();
  const selectionDetails = {
    startKey: selectionState.getStartKey(),
    endKey: selectionState.getEndKey(),
    anchorOffset: selectionState.getAnchorOffset(),
    anchorKey: selectionState.getAnchorKey(),
    focusKey: selectionState.getFocusKey(),
    focusOffset: selectionState.getFocusOffset(),
    isBackward: selectionState.getIsBackward(),
  };

  const contentBlockList = getEffectedStyledBlocks(
    contentState,
    selectionDetails,
    blockType
  );

  modifiedEditorState = RichUtils.toggleBlockType(editorState, blockType);

  modifiedEditorState = setBlockDepth(
    modifiedEditorState,
    contentBlockList,
    selectionDetails
  );

  return modifiedEditorState;
};
