import { EditorState } from 'draft-js';
import React from 'react';
import styles from '../../styles/toolbar.scss';
import createStore from './createStore';
import Toolbar from './toolbar';
interface IConfig {
  theme?: any;
}

export default (config: IConfig = {}) => {
  const store = createStore({
    isVisible: false,
  });
  const defaultTheme = {
    toolbarStyles: {
      toolbar: styles.toolbar,
    },
    buttonStyles: {
      button: styles.button,
      buttonWrapper: styles.buttonWrapper,
      active: styles.active,
    },
  };
  const { theme = defaultTheme } = config;
  const InlineToolbar = (props) => (
    <Toolbar {...props} store={store} theme={theme} />
  );

  return {
    initialize: ({ getEditorState, setEditorState, getEditorRef }) => {
      store.updateItem('getEditorState', getEditorState);
      store.updateItem('setEditorState', setEditorState);
      store.updateItem('getEditorRef', getEditorRef);
    },
    // Re-Render the text-toolbar on selection change
    onChange: (
      editorState: EditorState,
      { getEditorState, setEditorState, getEditorRef }
    ) => {
      store.updateItem('selection', editorState.getSelection());
      store.updateItem('getEditorState', getEditorState);
      store.updateItem('setEditorState', setEditorState);
      store.updateItem('getEditorRef', getEditorRef);
      return editorState;
    },
    onFocus: () => {
      store.updateItem('focus', true);
    },
    onBlur: () => {
      store.updateItem('blur', false);
    },
    InlineToolbar,
  };
};
