import { INote } from '../../api/INotesResponse';
import { INotesEntryProps } from '../../NotesEntry';
import uiReducer, { IUI, uiState } from '../ui/reducer';
import * as actions from './actions';
import * as operations from './operations';

export interface INotes {
  acceptErrorId?: string;
  createError?: boolean;
  createPending?: boolean;
  data?: INote[];
  deleteErrorId?: string;
  editErrorId?: string;
  pending?: boolean;
  error?: any;
  ui: IUI;
  recordingEpochTimes?: INotesEntryProps['recordingEpochTimes'];
}

export const notesState: INotes = {
  data: [],
  ui: uiState,
};

export default (state = notesState, action): INotes => {
  state.ui = uiReducer(state.ui, action);

  switch (action.type) {
    case actions.getNotes.fulfilled.toString():
      return {
        ...state,
        data: state.recordingEpochTimes
          ? operations.correlateNotesToVideo(
              action.payload,
              state.recordingEpochTimes
            )
          : action.payload,
        pending: false,
      };
    case actions.getNotes.pending.toString():
      return {
        ...state,
        pending: true,
      };
    case actions.getNotes.rejected.toString():
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    case actions.addVideoCorelation.toString():
      return {
        ...state,
        data: operations.correlateNotesToVideo(state.data, action.payload),
        recordingEpochTimes: action.payload,
      };
    case actions.createNote.pending.toString():
      return {
        ...state,
        createPending: true,
      };
    case actions.createNote.fulfilled.toString():
      return {
        ...state,
        data: state.recordingEpochTimes
          ? [
              ...state.data,
              ...operations.correlateNotesToVideo(
                [action.payload],
                state.recordingEpochTimes
              ),
            ]
          : [...state.data, action.payload],
        createPending: false,
      };
    case actions.createNote.rejected.toString():
      return {
        ...state,
        createPending: false,
        createError: true,
      };
    case actions.editNote.pending.toString():
      return {
        ...state,
        editErrorId: undefined,
      };
    case actions.editNote.fulfilled.toString():
      return {
        ...state,
        data: state.data.map((note) =>
          note.id === action.payload.id ? { ...note, ...action.payload } : note
        ),
      };
    case actions.editNote.rejected.toString():
      return {
        ...state,
        editErrorId: action.payload.id,
      };
    case actions.denyNote.pending.toString():
    case actions.deleteNote.pending.toString():
      return {
        ...state,
        deleteErrorId: undefined,
      };
    case actions.denyNote.fulfilled.toString():
    case actions.deleteNote.fulfilled.toString():
      return {
        ...state,
        data: state.data.filter((note) => note.id !== action.payload),
      };
    case actions.denyNote.rejected.toString():
    case actions.deleteNote.rejected.toString():
      return {
        ...state,
        deleteErrorId: action.payload.id,
      };
    case actions.acceptNote.fulfilled.toString():
      return {
        ...state,
        data: state.data.map((note) =>
          note.id === action.payload.id ? { ...note, ...action.payload } : note
        ),
      };
    case actions.acceptNote.rejected.toString():
      return {
        ...state,
        acceptErrorId: action.payload,
      };
    case actions.acceptNote.pending.toString():
      return {
        ...state,
        acceptErrorId: undefined,
      };
    case actions.clearCreateError.toString():
      return {
        ...state,
        createError: false,
      };
    default: {
      return state;
    }
  }
};
