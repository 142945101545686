import React from 'react';
import { TranslationContext } from '../../context/TranslationsContext';
import styles from './ErrorComponent.scss';
class SpecialCharError extends React.Component {
  static contextType = TranslationContext;

  render() {
    return (
      <div className={styles.errMsg}>
        {this.context.spcialCharacterErrorMsg}
      </div>
    );
  }
}

export default SpecialCharError;
