/* eslint-disable react/no-children-prop */
import clsx from 'clsx';
import React from 'react';
import { setBlockStyling } from '../../utitlity/draftUtility';

export default ({ blockType, children, isDraftBlock = true }) => (props) => {
  const { onToolSelected } = props;
  const toggleStyle = (event) => {
    event.preventDefault();
    const styleApplied = !blockTypeIsActive();
    onToolSelected({
      name: blockType.toUpperCase(),
      enabled: styleApplied,
    });

    if (isDraftBlock) {
      props.setEditorState(setBlockStyling(props.getEditorState(), blockType));
    }
  };

  const preventBubblingUp = (event) => {
    event.preventDefault();
  };

  const blockTypeIsActive = () => {
    // if the button is rendered before the editor
    if (!props.getEditorState) {
      return false;
    }

    const editorState = props.getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return type === blockType;
  };

  const { theme } = props;
  const className = blockTypeIsActive()
    ? clsx(theme.button, theme.active)
    : theme.button;
  return (
    <div
      className={`${theme.buttonWrapper} ${props.className}`}
      onMouseDown={preventBubblingUp}
    >
      <button
        className={className}
        onClick={toggleStyle}
        type="button"
        children={children}
      />
    </div>
  );
};
