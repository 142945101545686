import Editor from '@draft-js-plugins/editor';
import Draft, {
  ContentBlock,
  ContentState,
  convertFromRaw,
  DraftHandleValue,
  EditorState,
  KeyBindingUtil,
  RawDraftContentBlock,
  RichUtils,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Map } from 'immutable';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  ARROWORMOUSECLICK,
  ENTERKEY,
  EXCEEDEDCHARACTERLIMIT,
  MOVETONEXTNOTE,
  MULTINOTEDELETEDISABLED,
  NOTECHARACTERSLIMIT,
  NOTECOUNTLIMIT,
  NOTECOUNTLIMITEXCEEDED,
  SPECIALCHARACTERERROR,
  TOOLBARDISABLED,
  TOOLUSED,
} from '../constants';
import { TranslationContext } from '../context/TranslationsContext';
import { getCurrentNotesBlocks } from '../converter/backendDraftJSbackendAdapter';
import plugins from '../plugins';
import customStyleMap from '../plugins/styleDecorationPlugin';
import '../styles/draftjsOverrides.css';
import styles from '../styles/index.scss';
import { scrollToLastNote } from '../utitlity/domUtility';
import {
  getCurrentBlock,
  getNewContentBlock,
  insertNewBlock,
} from '../utitlity/draftUtility';
import { isSpecialCharacterPresent } from '../utitlity/notesUtility';
import { IProps, IStateProps } from './EditorProps';
import myBlockRenderer from './ErrorComponent/blockRenderer';
import ErrorComponent from './ErrorComponent/ErrorComponent';
import InlineToolbarComponent from './Toolbar';

const blockRenderMap = Map({
  noteDividier: {
    element: 'div',
  },
});
const extendedBlockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(
  blockRenderMap
);

class EditorComponent extends React.Component<IProps, IStateProps> {
  static contextType = TranslationContext;
  editor: Editor;
  isNewNote: boolean = false;
  noteMap = {};
  lastEditNoteKey = null;
  lastNoteSyncKey = null;
  toolBarEnabled = true;
  characterLimit = NOTECHARACTERSLIMIT;
  backspace = false;
  errorMessage = undefined;
  noteCountError: boolean = false;
  constructor(props: Readonly<IProps>) {
    super(props);

    const isInitialStateEmpty = !(
      props.initialState && Object.keys(props.initialState).length !== 0
    );
    let editorState = isInitialStateEmpty
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertFromRaw(props.initialState));

    // blocking undo operations in session as it can have multiple notes.
    editorState = EditorState.set(editorState, {
      allowUndo: false,
    });
    this.state = {
      editorState,
    };
    this.noteMap = this.props.notesMap || {};
    this.isNewNote = Object.keys(this.props.notesMap).length > 0 ? false : true;
    this.noteCountError =
      Object.keys(this.props.notesMap).length >= NOTECOUNTLIMIT;
  }

  onChange = (editorState: EditorState): void => {
    const currentBlock = getCurrentBlock(editorState);
    const currentEditingBlockKey = currentBlock.getKey();
    const contentState = editorState.getCurrentContent();
    const {
      startKey: currentNoteStartKey,
      notesBlock: currentNotesBlock,
    } = getCurrentNotesBlocks(
      contentState,
      this.noteMap,
      currentEditingBlockKey
    );
    let modifiedEditorState = editorState;
    let notesText = '';
    currentNotesBlock.forEach((textBlock) => {
      notesText += textBlock.text;
    });

    this.handleSpecialCharacter(notesText);
    modifiedEditorState = this.handleExceedCharacter(
      modifiedEditorState,
      notesText
    );

    this.handleSave(currentNoteStartKey, contentState, editorState);

    if (this.isNewNote) {
      this.noteMap[currentEditingBlockKey] = true;
    }

    this.isNewNote = false;
    this.lastEditNoteKey = currentEditingBlockKey;
    this.toolBarEnabled =
      currentBlock.getText().length > 0 &&
      !this.isMultiNoteSelected(editorState);
    this.backspace = false;
    if (Object.keys(this.noteMap).length === 0) {
      this.noteMap[currentEditingBlockKey] = true;
    }

    this.setState({ editorState: modifiedEditorState });
  };

  handleSave = (
    currentNoteStartKey: string,
    contentState: ContentState,
    editorState: EditorState
  ) => {
    if (
      this.lastNoteSyncKey !== currentNoteStartKey &&
      Object.keys(this.noteMap).length <= NOTECOUNTLIMIT
    ) {
      const {
        startKey: lastSyncNoteStartKey,
        notesBlock,
      } = getCurrentNotesBlocks(
        contentState,
        this.noteMap,
        this.lastNoteSyncKey
      );
      // this part make sure moving to different content would save the last note
      this.props.syncNotes(
        lastSyncNoteStartKey,
        notesBlock,
        editorState,
        contentState
      );
      this.lastNoteSyncKey = currentNoteStartKey;
      const action = this.isNewNote ? ENTERKEY : ARROWORMOUSECLICK;
      this.props.mixPanelTrack(MOVETONEXTNOTE, { [action]: true });
    }
  };

  handleSpecialCharacter = (notesText: string) => {
    this.errorMessage = isSpecialCharacterPresent(notesText)
      ? this.context.spcialCharacterErrorMsg
      : this.errorMessage === this.context.spcialCharacterErrorMsg
      ? undefined
      : this.errorMessage;

    this.errorMessage && this.props.mixPanelTrack(SPECIALCHARACTERERROR);
  };

  handleExceedCharacter = (editorState: EditorState, noteText: string) => {
    let modifiedEditorState = editorState;

    if (noteText.length >= this.characterLimit) {
      this.errorMessage = this.context.noteError;
      if (editorState.getLastChangeType() === 'insert-characters') {
        modifiedEditorState = this.state.editorState;
      }
    } else {
      this.errorMessage =
        this.errorMessage === this.context.noteError
          ? undefined
          : this.errorMessage;
    }
    this.errorMessage && this.props.mixPanelTrack(EXCEEDEDCHARACTERLIMIT);

    return modifiedEditorState;
  };

  onTab = (event: any) => {
    event.preventDefault();
    const maxDepth = 4;

    const newState = RichUtils.onTab(event, this.state.editorState, maxDepth);
    this.setState({
      editorState: newState,
    });
  };

  onToolUsed = (eventProps) => {
    this.props.mixPanelTrack(TOOLUSED, eventProps);
  };

  focus = () => {
    this.editor.focus();
  };

  handleMovingTolastNote = (
    editorState: EditorState,
    currentNoteStartKey: string,
    isLastBlockEmpty,
    lastBlockNotesBlock
  ) => {
    const notesMapList = Object.keys(this.noteMap);
    const notesLength = notesMapList.length;
    const selectionBlockKey = notesMapList[notesLength - 1];

    if (notesLength >= NOTECOUNTLIMIT) {
      // this is to move last block which cannot
      this.props.mixPanelTrack(NOTECOUNTLIMITEXCEEDED);
      this.noteCountError = true;
      this.toolBarEnabled = false;
      return EditorState.forceSelection(
        editorState,
        editorState.getSelection().merge({
          anchorOffset: 0,
          focusOffset: 0,
          anchorKey: selectionBlockKey,
          focusKey: selectionBlockKey,
        })
      );
    } else if (!isLastBlockEmpty && lastBlockNotesBlock.length <= 2) {
      // this one is to handle empty last block and move to that block
      return EditorState.moveFocusToEnd(editorState);
    }
  };

  insertEmptyBlockAtTheEnd = (contentStateWithModification, editorState) => {
    const { key, newBlock } = getNewContentBlock();
    this.noteMap[key] = true;
    const blockMap = contentStateWithModification.getBlockMap();
    const newBlockMap = blockMap.set(newBlock.getKey(), newBlock);
    const modifiedContentState = ContentState.createFromBlockArray(
      newBlockMap.toArray()
    );
    const editorStateAfterInsertingBlock = EditorState.push(
      editorState,
      modifiedContentState,
      'adjust-depth'
    );
    const modifiedEditorState = EditorState.forceSelection(
      editorStateAfterInsertingBlock,
      modifiedContentState.getSelectionAfter()
    );
    const finalEditorState = EditorState.moveFocusToEnd(modifiedEditorState);
    return finalEditorState;
  };

  moveToNextNote = (
    editorState: EditorState,
    currentBlockKey: string
  ): EditorState => {
    const contenState = editorState.getCurrentContent();
    const lastBlockKey = contenState.getLastBlock();
    const { startKey, notesBlock } = getCurrentNotesBlocks(
      contenState,
      this.noteMap,
      currentBlockKey
    );
    const lastBlock =
      notesBlock.length - 1 >= 0 ? notesBlock[notesBlock.length - 1] : null;
    let contentStateWithModification = contenState;
    if (lastBlock.type !== 'noteDividier') {
      contentStateWithModification = insertNewBlock(
        'after',
        lastBlock.key,
        editorState,
        'noteDividier'
      );
    }
    this.props.syncNotes(
      startKey,
      notesBlock,
      editorState,
      contentStateWithModification
    );
    const { notesBlock: lastBlockNotesBlock } = getCurrentNotesBlocks(
      contentStateWithModification,
      this.noteMap,
      lastBlockKey.getKey()
    );
    const isLastBlockEmpty = lastBlockNotesBlock.find(
      (note: RawDraftContentBlock) => note.text.length > 0
    );
    this.props.mixPanelTrack(MOVETONEXTNOTE, { ENTERKEY: true });
    scrollToLastNote(styles.noteStyle);

    const newEditorState = this.handleMovingTolastNote(
      editorState,
      startKey,
      isLastBlockEmpty,
      lastBlockNotesBlock
    );

    return newEditorState
      ? newEditorState
      : this.insertEmptyBlockAtTheEnd(
          contentStateWithModification,
          editorState
        );
  };

  removeBlock = (
    editorState: EditorState,
    blockType: string,
    blockKey: string
  ): EditorState => {
    const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
    const blockMapAfterRemoving = newEditorState
      .getCurrentContent()
      .getBlockMap()
      .remove(blockKey);
    const newContentState = ContentState.createFromBlockArray(
      blockMapAfterRemoving.toArray()
    );
    return EditorState.push(newEditorState, newContentState, 'adjust-depth');
  };
  addNoteStyle = (contentBlock: ContentBlock) => {
    if (this.noteMap[contentBlock.getKey()]) {
      return styles.noteStyle;
    }
  };

  returnHandler = (event, editorState: EditorState): DraftHandleValue => {
    const block: ContentBlock = getCurrentBlock(editorState);
    const contentState = editorState.getCurrentContent();
    const blockType = block.getType();
    const blockKey = block.getKey();
    const blockText = block.getText();
    const isBlockTextEmpty = blockText.length === 0;
    const isListing =
      blockType === 'unordered-list-item' || blockType === 'ordered-list-item';
    const lastBlockKey = contentState.getKeyBefore(blockKey);
    this.lastEditNoteKey = blockKey;

    if (isListing) {
      if (isBlockTextEmpty) {
        const newEditorState = this.removeBlock(
          editorState,
          blockType,
          blockKey
        );
        this.setState({
          editorState: this.moveToNextNote(newEditorState, lastBlockKey),
        });
        return 'handled';
      }
    } else if (!KeyBindingUtil.isSoftNewlineEvent(event)) {
      this.setState({
        editorState: this.moveToNextNote(editorState, blockKey),
      });
      return 'handled';
    }

    if (isBlockTextEmpty) {
      return 'handled';
    }
    return 'not-handled';
  };

  isMultiNoteSelected = (editorState: EditorState): boolean => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startSelectedKey = selectionState.getStartKey();
    const endSelectedKey = selectionState.getEndKey();
    const { startKey } = getCurrentNotesBlocks(
      contentState,
      this.noteMap,
      startSelectedKey
    );
    const { startKey: noteStateKeyForEndSelection } = getCurrentNotesBlocks(
      contentState,
      this.noteMap,
      endSelectedKey
    );
    const status = startKey !== noteStateKeyForEndSelection;

    if (status) {
      this.props.mixPanelTrack(TOOLBARDISABLED);
    }
    return status;
  };

  handlePartialDeleteOfNotes = (
    command: string,
    editorState: EditorState
  ): DraftHandleValue => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startSelectedKey = selectionState.getStartKey();
    const endSelectedKey = selectionState.getEndKey();
    const startOffset = selectionState.getStartOffset();
    const endOffset = selectionState.getEndOffset();
    const {
      startKey: noteStartKey,
      endKey: noteEndKey,
      notesBlock,
    } = getCurrentNotesBlocks(contentState, this.noteMap, startSelectedKey);
    const { startKey: noteStartKey1 } = getCurrentNotesBlocks(
      contentState,
      this.noteMap,
      endSelectedKey
    );
    let notesText = '';
    notesBlock.forEach((textBlock) => {
      notesText += textBlock.text;
    });
    const isNotesTextEmpty = notesText.length === 0;
    const currentBlockKey = getCurrentBlock(editorState).getKey();
    const isCurrentBlockIsFirstBlock = currentBlockKey === noteStartKey;
    const doNotMergeNotes = isCurrentBlockIsFirstBlock && !isNotesTextEmpty;

    if (command === 'backspace' || command === 'delete') {
      this.backspace = true;
      if (isNotesTextEmpty) {
        this.props.deleteNote(noteStartKey, editorState);
        delete this.noteMap[noteStartKey];
        this.noteCountError =
          Object.keys(this.noteMap).length >= NOTECOUNTLIMIT;
      } else if (
        startSelectedKey === noteStartKey &&
        endSelectedKey === noteEndKey &&
        startOffset === 0 &&
        endOffset === 0
      ) {
        this.props.deleteNote(noteStartKey, editorState);
        delete this.noteMap[noteStartKey];
        this.noteCountError =
          Object.keys(this.noteMap).length >= NOTECOUNTLIMIT;
      }

      if (startOffset === 0 && endOffset === 0 && doNotMergeNotes) {
        return 'handled';
      }
      if (noteStartKey !== noteStartKey1) {
        this.props.mixPanelTrack(MULTINOTEDELETEDISABLED);
        return 'handled';
      }
    }

    return 'not-handled';
  };

  hidePlaceholderForListItems = () => {
    const { editorState } = this.state;

    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        className += ' RichEditor-hidePlaceholder';
      }
    }
    return className;
  };

  handlePastedTextHandler = (
    text: string,
    html,
    editorState: EditorState
  ): DraftHandleValue => {
    const currentBlock = getCurrentBlock(editorState);
    const key = currentBlock.getKey();
    const contentState = editorState.getCurrentContent();
    const { notesBlock } = getCurrentNotesBlocks(
      contentState,
      this.noteMap,
      key
    );
    let notesText = '';

    notesBlock.forEach((textBlock) => {
      notesText += textBlock.text;
    });
    const alloweCharactersToPase = this.characterLimit - notesText.length;
    if (text.length > alloweCharactersToPase) {
      return 'handled';
    }
  };

  render() {
    const className = this.hidePlaceholderForListItems();

    return (
      <div
        onClick={this.focus}
        className={`${styles.editor} ${this.props.className} ${className}`}
        id={this.props.id}
      >
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          defaultKeyCommands={true}
          defaultKeyBindings={true}
          onTab={this.onTab}
          plugins={plugins}
          ref={(ele) => {
            this.editor = ele;
          }}
          placeholder={this.context.notePlaceHolderText}
          readOnly={this.props.readonly}
          handleReturn={this.returnHandler}
          handleKeyCommand={this.handlePartialDeleteOfNotes}
          blockStyleFn={this.addNoteStyle}
          blockRendererFn={myBlockRenderer}
          handlePastedText={this.handlePastedTextHandler}
          customStyleMap={customStyleMap}
          blockRenderMap={extendedBlockRenderMap}
        />
        {this.noteCountError && <ErrorComponent marginTop="largeMarginTop" />}
        {!this.props.readonly && this.toolBarEnabled && (
          <InlineToolbarComponent
            onChange={this.onChange}
            editorState={this.state.editorState}
            onToolUsed={this.onToolUsed}
            scrollingEleId={this.props.scrollingElementId}
            errorMessage={this.errorMessage}
          />
        )}
        <ReactTooltip effect="solid" type="dark" />
      </div>
    );
  }
}

export default EditorComponent;
