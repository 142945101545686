import Editor from '@draft-js-plugins/editor';
import clsx from 'clsx';
import {
  ContentBlock,
  ContentState,
  convertFromRaw,
  DraftHandleValue,
  EditorState,
  KeyBindingUtil,
  RichUtils,
} from 'draft-js';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  EXCEEDEDCHARACTERLIMIT,
  NOTECHARACTERSLIMIT,
  SPECIALCHARACTERERROR,
  TOOLUSED,
} from '../constants';
import { TranslationContext } from '../context/TranslationsContext';
// import { TOOLUSED } from './constants';
import plugins from '../plugins';
import styles from '../styles/index.scss';
import { getCurrentBlock } from '../utitlity/draftUtility';
import { isSpecialCharacterPresent } from '../utitlity/notesUtility';
import myBlockRenderer from './ErrorComponent/blockRenderer';
import InlineToolbarComponent from './Toolbar';

interface IPostSessionProps {
  initialState: any;
  readonly: boolean;
  scrollingElementId?: string;
  id: string;
  containerId: string;
  reset: boolean;
  createNoteFlow: boolean;
  className?: string;
  totalNotes?: number;
  mixPanelTrack?(name: string, props?: any): void;
  handleNoteEdit(editorId: string, readOnly?: boolean): void;
  syncNotes(editor: EditorState): void;
}
interface IPostEditorState {
  editorState: EditorState;
  readonly: boolean;
  initialState: any;
}
class PostSessionEditor extends React.Component<
  IPostSessionProps,
  IPostEditorState
> {
  static contextType = TranslationContext;
  editor: Editor;
  characterLimit = NOTECHARACTERSLIMIT;
  toolbarEnabled = true;
  backspace: boolean;
  linkFormBlurEvent = false;
  changeCount = 0;
  errorMessage = null;

  constructor(props: Readonly<IPostSessionProps>) {
    super(props);
    const { readonly, initialState } = props;

    let editorState = this.getEditorState(initialState);
    // blocking undo operations in session as it can have multiple notes.
    editorState = EditorState.set(editorState, {
      allowUndo: false,
    });
    this.state = {
      editorState,
      readonly,
      initialState,
    };
  }

  getEditorState = (initialState) => {
    const isInitialStateEmpty = !(
      initialState && Object.keys(initialState).length !== 0
    );
    const editorState = isInitialStateEmpty
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertFromRaw(initialState));

    return editorState;
  };
  componentWillReceiveProps(props: IPostSessionProps) {
    if (props.reset) {
      this.setState({
        editorState: EditorState.createEmpty(),
      });
    }
  }

  onChange = (editorState: EditorState): void => {
    this.changeCount++;
    const contentState = editorState.getCurrentContent();
    const noteText = contentState.getPlainText();
    let modifiedEditorState = editorState;

    this.handleSpecialCharacter(noteText);
    modifiedEditorState = this.handleExceedCharacter(
      modifiedEditorState,
      noteText
    );
    this.toolbarEnabled = noteText.length > 0;

    // reset backspace props
    this.backspace = false;
    this.setState({ editorState: modifiedEditorState });
  };

  handleExceedCharacter = (editorState: EditorState, noteText: string) => {
    let modifiedEditorState = editorState;

    if (noteText.length >= this.characterLimit) {
      this.errorMessage = this.context.noteError;
      if (editorState.getLastChangeType() === 'insert-characters') {
        modifiedEditorState = this.state.editorState;
      }
    } else {
      this.errorMessage =
        this.errorMessage === this.context.noteError
          ? undefined
          : this.errorMessage;
    }
    this.errorMessage && this.props.mixPanelTrack(EXCEEDEDCHARACTERLIMIT);

    return modifiedEditorState;
  };
  handleSpecialCharacter = (notesText: string) => {
    this.errorMessage = isSpecialCharacterPresent(notesText)
      ? this.context.spcialCharacterErrorMsg
      : this.errorMessage === this.context.spcialCharacterErrorMsg
      ? undefined
      : this.errorMessage;

    this.errorMessage && this.props.mixPanelTrack(SPECIALCHARACTERERROR);
  };

  handlePartialDeleteOfNotes = (command: string): DraftHandleValue => {
    if (command === 'backspace' || command === 'delete') {
      this.backspace = true;
    }
    return 'not-handled';
  };

  onTab = (event: any) => {
    const maxDepth = 4;

    const newState = RichUtils.onTab(event, this.state.editorState, maxDepth);
    this.setState({
      editorState: newState,
    });
  };

  onToolUsed = (eventProps) => {
    this.props.mixPanelTrack(TOOLUSED, eventProps);
  };

  focus = (event) => {
    const targetEle: HTMLElement = event.target;
    const isLinkWrappedTarget =
      targetEle.parentNode &&
      targetEle.parentNode.parentNode &&
      targetEle.parentNode.parentNode.nodeName.toLowerCase() === 'a';
    this.editor.focus();
    if (!isLinkWrappedTarget && this.props.createNoteFlow) {
      this.props.handleNoteEdit(this.props.id, false);
    }
  };
  removeBlock = (
    editorState: EditorState,
    blockType: string,
    blockKey: string
  ): EditorState => {
    const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
    const blockMapAfterRemoving = newEditorState
      .getCurrentContent()
      .getBlockMap()
      .remove(blockKey);
    const newContentState = ContentState.createFromBlockArray(
      blockMapAfterRemoving.toArray()
    );
    return EditorState.push(newEditorState, newContentState, 'adjust-depth');
  };
  returnHandler = (event, editorState: EditorState): DraftHandleValue => {
    const block: ContentBlock = getCurrentBlock(editorState);
    const blockType = block.getType();
    const blockKey = block.getKey();
    const blockText = block.getText();
    const isBlockTextEmpty = blockText.length === 0;
    const isListing =
      blockType === 'unordered-list-item' || blockType === 'ordered-list-item';
    if (isListing) {
      if (isBlockTextEmpty) {
        const newEditorState = this.removeBlock(
          editorState,
          blockType,
          blockKey
        );
        this.setState({
          editorState: newEditorState,
        });
        this.saveNote(newEditorState);
        return 'handled';
      }
      return 'not-handled';
    }
    if (KeyBindingUtil.isSoftNewlineEvent(event)) {
      return 'not-handled';
    }

    this.saveNote(editorState);
    return 'handled';
  };

  hidePlaceholderForListItems = () => {
    const { editorState } = this.state;

    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        className += ' RichEditor-hidePlaceholder';
      }
    }
    return className;
  };

  handlePastedTextHandler = (
    text: string,
    html,
    editorState: EditorState
  ): DraftHandleValue => {
    const notesText = editorState.getCurrentContent().getPlainText();
    const alloweCharactersToPase = this.characterLimit - notesText.length;
    if (text.length > alloweCharactersToPase) {
      return 'handled';
    }
    if (text.indexOf('<') > -1) {
      return 'handled';
    }
  };
  saveNote = (editorState: EditorState) => {
    const text = editorState.getCurrentContent().getPlainText();
    if (this.errorMessage) {
      return;
    }

    if (text.length > 0 && this.toolbarEnabled) {
      this.props.syncNotes(editorState);
    }
  };
  blurHandler = (event) => {
    if (!this.props.createNoteFlow && !this.linkFormBlurEvent) {
      this.saveNote(this.state.editorState);
    }
    if (this.props.createNoteFlow && !this.linkFormBlurEvent) {
      this.toolbarEnabled = false;
    }
    this.linkFormBlurEvent = false;
  };

  setLinkFormBlurEvent = (status: boolean) => {
    this.linkFormBlurEvent = status;
  };
  render() {
    const className = this.hidePlaceholderForListItems();

    return (
      <div
        onClick={this.focus}
        className={clsx(
          styles.editor,
          styles.postSessionEditor,
          className,
          this.props.className
        )}
        id={this.props.id}
        onBlur={this.blurHandler}
      >
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          defaultKeyCommands={true}
          defaultKeyBindings={true}
          onTab={this.onTab}
          plugins={plugins}
          ref={(ele) => {
            this.editor = ele;
          }}
          placeholder={this.context.notePlaceHolderText}
          readOnly={this.props.readonly}
          handleReturn={this.returnHandler}
          handlePastedText={this.handlePastedTextHandler}
          blockRendererFn={myBlockRenderer}
          handleKeyCommand={this.handlePartialDeleteOfNotes}
        />
        {!this.props.readonly && this.toolbarEnabled && (
          <InlineToolbarComponent
            onChange={this.onChange}
            editorState={this.state.editorState}
            onToolUsed={this.onToolUsed}
            scrollingEleId={this.props.containerId}
            linkBlurEvent={this.setLinkFormBlurEvent}
            errorMessage={this.errorMessage}
          />
        )}
        <ReactTooltip effect="solid" type="dark" />
      </div>
    );
  }
}

export default PostSessionEditor;
