import ErrorComponent from './ErrorComponent';
import NoteDivider from './NoteDivider';
import SpecialCharError from './SpecialCharacterError';

const myBlockRenderer = (contentBlock) => {
  const type = contentBlock.getType();

  switch (type) {
    case 'exceedCharacterLimit':
      return {
        component: ErrorComponent,
        editable: false,
      };
    case 'noteDividier':
      return {
        component: NoteDivider,
        editable: false,
      };
    case 'specialCharacterError':
      return {
        component: SpecialCharError,
        editable: false,
      };
  }
};

export default myBlockRenderer;
