import clsx from 'clsx';
import { Editor, EditorState } from 'draft-js';
import React from 'react';
import { createLinkAtSelection } from '../../utitlity/draftUtility';
import styles from './form.scss';
import URLUtils from './utility/urlUtils';

interface IAddLinkProps {
  placeholder: string;
  theme: {
    input: string;
    inputInvalid: string;
  };
  getEditorRef(): Editor;
  onOverrideContent(content: any);
  getEditorState(): EditorState;
  setEditorState(state: EditorState): EditorState;
}

export default class AddLinkForm extends React.Component<IAddLinkProps> {
  static defaultProps = {
    placeholder: 'Enter a URL and press enter', // localise
  };

  state = {
    value: '',
    isInvalid: false,
  };
  input: any;

  componentDidMount() {
    this.input.focus();
  }

  onRef = (node) => {
    this.input = node;
  };

  onChange = ({ target: { value } }) => {
    const nextState = { value };
    let isInvalid = this.state.isInvalid;
    if (this.state.isInvalid && URLUtils.isUrl(URLUtils.normalizeUrl(value))) {
      isInvalid = false;
    }
    this.setState({ ...nextState, isInvalid });
  };

  onClose = () => this.props.onOverrideContent(undefined);
  // onClose = () => {};

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submit();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      this.onClose();
    }
  };

  submit() {
    const { setEditorState, getEditorState } = this.props;
    let { value: url } = this.state;
    if (!URLUtils.isMail(URLUtils.normaliseMail(url))) {
      url = URLUtils.normalizeUrl(url);
      if (!URLUtils.isUrl(url)) {
        this.setState({ isInvalid: true });
        return;
      }
    } else {
      url = URLUtils.normaliseMail(url);
    }
    setEditorState(createLinkAtSelection(getEditorState(), url));
    this.input.blur();
    this.onClose();
  }

  render() {
    const { theme, placeholder } = this.props;
    const { value, isInvalid } = this.state;
    const className = isInvalid
      ? clsx(theme.input, theme.inputInvalid)
      : theme.input;

    return (
      <div className={styles.input_container}>
        <input
          className={className}
          onBlur={this.onClose}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          ref={this.onRef}
          type="text"
          value={value}
        />
      </div>
    );
  }
}
