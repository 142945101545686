exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".shareable-ui-src-plugins-linkPlugin-form--toolbar_input_form {\n  height: 34px;\n  width: 220px;\n  padding: 0 12px;\n  font-size: 15px;\n  font-family: inherit;\n  background-color: transparent;\n  color: #444;\n  border-radius: 4px;\n  border: solid 1px rgba(76, 83, 93, 0.24);\n  box-shadow: inset 0 1px 8px -3px #ababab; }\n  .shareable-ui-src-plugins-linkPlugin-form--toolbar_input_form:focus {\n    outline: none; }\n  .shareable-ui-src-plugins-linkPlugin-form--toolbar_input_form::-webkit-input-placeholder {\n    color: #888; }\n  .shareable-ui-src-plugins-linkPlugin-form--toolbar_input_form::-moz-placeholder {\n    color: #888; }\n  .shareable-ui-src-plugins-linkPlugin-form--toolbar_input_form:-ms-input-placeholder {\n    color: #888; }\n  .shareable-ui-src-plugins-linkPlugin-form--toolbar_input_form::-ms-input-placeholder {\n    color: #888; }\n  .shareable-ui-src-plugins-linkPlugin-form--toolbar_input_form::placeholder {\n    color: #888; }\n  .shareable-ui-src-plugins-linkPlugin-form--input_container {\n  height: 70px;\n  width: 260px;\n  border-radius: 4px;\n  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.04), 0 8px 10px 1px rgba(0, 0, 0, 0.08), 0 5px 5px -3px rgba(0, 0, 0, 0.12);\n  border: solid 1px rgba(76, 83, 93, 0.24);\n  background-color: #ffffff;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center; }\n  .shareable-ui-src-plugins-linkPlugin-form--link_tag {\n  text-decoration: underline;\n  color: #006aff;\n  line-height: 22.4px; }\n  .shareable-ui-src-plugins-linkPlugin-form--invalid_url {\n  color: #e65757; }\n", ""]);

// Exports
exports.locals = {
	"toolbar_input_form": "shareable-ui-src-plugins-linkPlugin-form--toolbar_input_form",
	"input_container": "shareable-ui-src-plugins-linkPlugin-form--input_container",
	"link_tag": "shareable-ui-src-plugins-linkPlugin-form--link_tag",
	"invalid_url": "shareable-ui-src-plugins-linkPlugin-form--invalid_url"
};