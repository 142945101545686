import Hashtag from './hashTag';
const HASHTAG = 'HASHTAG';
export const hashtagStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === HASHTAG
    );
  }, callback);
};

const hashTagMethod = () => {
  return {
    decorators: [
      {
        strategy: hashtagStrategy,
        component: Hashtag,
      },
    ],
  };
};

const hashTagPlugin = hashTagMethod();
export default hashTagPlugin;
