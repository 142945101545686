import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from '../../../styles/toolbar.scss';
import hashTagHOC from '../../HOC/hashTagHOC';

export default hashTagHOC(
  'actionItem',
  <>
    <span
      data-tip={'Action item'}
      data-offset="{'top': 4}"
      data-for="actionItemOverlay"
      data-effect="solid"
    >
      <svg width="16" height="18">
        <defs>
          <filter id="filter-1">
            <feColorMatrix
              in="SourceGraphic"
              type="matrix"
              values="0 0 0 0 0.145098 0 0 0 0 0.156863 0 0 0 0 0.176471 0 0 0 1.000000 0"
            />
          </filter>
        </defs>
        <g
          id="Notes"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Notes---Wide---Phase-2"
            transform="translate(-751.000000, -645.000000)"
          >
            <g id="Popover" transform="translate(197.000000, 632.000000)">
              <g id="Action-Item" transform="translate(554.000000, 13.000000)">
                <g id="toggle/checkbox" filter="url(#filter-1)">
                  <g>
                    <rect
                      id="24px-Bounding-Box"
                      fillRule="nonzero"
                      x="0"
                      y="0"
                      width="18"
                      height="18"
                    />
                    <rect
                      id="Rectangle"
                      stroke="#B3BFCF"
                      x="3.5"
                      y="3.5"
                      width="11"
                      height="11"
                      rx="2"
                    />
                  </g>
                </g>
                <g
                  id="checkbox"
                  className={styles.nestedSvg}
                  transform="translate(1.000000, 1.000000)"
                >
                  <rect
                    id="24px-Bounding-Box"
                    fillRule="nonzero"
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                  />
                  <path
                    d="M6.3570226 10.7949254 L4.47140452 8.90930735 C4.21105499 8.64895783 4.21105499 8.22684784 4.47140452 7.96649831 C4.73175405 7.70614878 5.15386403 7.70614878 5.41421356 7.96649831 L6.82842712 9.38071187 L11.0710678 5.13807119 C11.3314173 4.87772166 11.7535273 4.87772166 12.0138769 5.13807119 C12.2742264 5.39842072 12.2742264 5.8205307 12.0138769 6.08088023 L7.29983165 10.7949254 C7.03948212 11.055275 6.61737213 11.055275 6.3570226 10.7949254 Z"
                    id="Shape"
                    fill="#25282D"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
    <ReactTooltip id="actionItemOverlay" />
  </>
);
