exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".shareable-ui-src-components-Toolbar-Buttons-styles-button--bold-icon-container {\n  font-size: 14px;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.4;\n  letter-spacing: normal;\n  text-align: center; }\n\n.shareable-ui-src-components-Toolbar-Buttons-styles-button--decision-icon-container {\n  font-size: 18px;\n  font-weight: 600;\n  font-weight: bold;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.4;\n  letter-spacing: normal;\n  text-align: center; }\n\n.shareable-ui-src-components-Toolbar-Buttons-styles-button--italic-icon-container {\n  font-size: 14px;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.4;\n  letter-spacing: normal;\n  text-align: center;\n  font-family: Georgia;\n  font-style: italic; }\n\n.shareable-ui-src-components-Toolbar-Buttons-styles-button--strike-icon-container {\n  font-size: 15px;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.4;\n  letter-spacing: normal;\n  text-align: center;\n  text-decoration: line-through; }\n", ""]);

// Exports
exports.locals = {
	"bold-icon-container": "shareable-ui-src-components-Toolbar-Buttons-styles-button--bold-icon-container",
	"decision-icon-container": "shareable-ui-src-components-Toolbar-Buttons-styles-button--decision-icon-container",
	"italic-icon-container": "shareable-ui-src-components-Toolbar-Buttons-styles-button--italic-icon-container",
	"strike-icon-container": "shareable-ui-src-components-Toolbar-Buttons-styles-button--strike-icon-container"
};