import React from 'react';
import { INote } from '../../api/INotesResponse';
import Icon from '../Icon/Icon';
import styles from './Toolbar.scss';

export interface IToolbarProps {
  isActive: boolean;
  isOwner: boolean;
  origin: INote['origin'];
  onEdit: () => void;
  onDelete: (evt: React.MouseEvent) => void;
  onBlur: () => void;
  onPlay: () => void;
  showPlay: boolean;
}

const Toolbar: React.SFC<IToolbarProps> = (props) => {
  return (
    <div className={styles.toolbar}>
      {props.origin !== 'insession' && props.showPlay && (
        <Icon
          active={false}
          icon={'togo-icon togo-icon-play'}
          onClick={props.onPlay}
          tooltip={'Play'}
          tooltipPosition={'bottom'}
        />
      )}
      {props.isOwner && (
        <>
          <Icon
            active={false}
            icon={'togo-icon-edit-filled'}
            onClick={props.onEdit}
            tooltip={'Edit'}
            tooltipPosition={'bottom'}
          />
          <Icon
            active={props.isActive}
            animateText={'Delete'}
            icon={'togo-icon-trash-filled'}
            onClick={props.onDelete}
            onBlur={props.onBlur}
            tooltip={'Delete'}
            tooltipPosition={'bottom'}
          />
        </>
      )}
    </div>
  );
};

export default Toolbar;
