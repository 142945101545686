import { inlineToolbarPlugin } from '../components/Toolbar';
import hashTagPlugin from './definedHashTagPlugin';
import specialCharactersErrorPlugin from './invalidCharacters';
import linkPlugin from './linkPlugin';

export default [
  inlineToolbarPlugin,
  linkPlugin,
  hashTagPlugin,
  specialCharactersErrorPlugin,
];
