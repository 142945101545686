import React from 'react';
import ReactTooltip from 'react-tooltip';
import { TranslationContext } from '../../../context/TranslationsContext';
import createBlockStyleButtons from '../../HOC/createBlockStyleButtons';

class ShowMore extends React.Component {
  static contextType = TranslationContext;

  render() {
    return (
      <>
        <span
          data-tip={this.context.showMore}
          data-offset="{'top': 4}"
          data-for="showMoreOverlay"
          data-effect="solid"
        >
          <svg width={`1em`} height={`1em`} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h24v24H0z" />
              <path
                d="M5.833 10c0 .92-.745 1.667-1.666 1.667-.921 0-1.667-.746-1.667-1.667 0-.92.746-1.667 1.667-1.667.92 0 1.666.746 1.666 1.667zm5.834 0c0 .92-.746 1.667-1.667 1.667-.92 0-1.667-.746-1.667-1.667 0-.92.746-1.667 1.667-1.667.92 0 1.667.746 1.667 1.667zm5.833 0c0 .92-.746 1.667-1.667 1.667-.92 0-1.666-.746-1.666-1.667 0-.92.745-1.667 1.666-1.667.921 0 1.667.746 1.667 1.667z"
                fill="#006AFF"
              />
            </g>
          </svg>
        </span>
        <ReactTooltip id="showMoreOverlay" />
      </>
    );
  }
}
export default createBlockStyleButtons({
  blockType: 'show-more-button',
  isDraftBlock: false,
  children: <ShowMore />,
});
