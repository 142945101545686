import { INote } from '../../api/INotesResponse';
import { INotesEntryProps } from '../../NotesEntry';

const calculateRecordingTimesWithPause = (
  recordingEpochTimes: INotesEntryProps['recordingEpochTimes']
) => {
  let pauseTime = 0;
  return recordingEpochTimes.map((recordingTime, i) => {
    pauseTime =
      i === 0
        ? pauseTime
        : pauseTime +
          (recordingTime.startTime - recordingEpochTimes[i - 1].stopTime);

    return {
      ...recordingTime,
      pauseTime,
    };
  });
};

export const correlateNotesToVideo = (
  notes: INote[],
  recordingEpochTimes: INotesEntryProps['recordingEpochTimes']
) => {
  if (!recordingEpochTimes.length || !notes) {
    return notes;
  }

  const recordingStartTime = recordingEpochTimes[0].startTime;
  const recordingEndTime =
    recordingEpochTimes[recordingEpochTimes.length - 1].stopTime;
  const recordingTimesWithPause = calculateRecordingTimesWithPause(
    recordingEpochTimes
  );
  const calculateNoteTime = (note: INote) => {
    let videoTime;
    if (note.startEpochTimeStamp < recordingStartTime) {
      videoTime = 0;
    } else if (note.startEpochTimeStamp > recordingEndTime) {
      videoTime = -1; // taking -1 to compare later and show/hide play button on UI
    } else {
      const timeObj = recordingTimesWithPause.find((time) => {
        const { startTime, stopTime } = time;
        // if the note time is withing a start and end time
        const withinRecordingTime =
          note.startEpochTimeStamp >= startTime &&
          note.startEpochTimeStamp <= stopTime;
        const lessThanStartTime = note.startEpochTimeStamp < startTime;

        if (!withinRecordingTime && lessThanStartTime) {
          return true;
        }

        return withinRecordingTime;
      });

      // If note start time is less than the current recording start time, then assume note is the current recording startTime
      videoTime =
        note.startEpochTimeStamp < timeObj.startTime
          ? timeObj.startTime - recordingStartTime - timeObj.pauseTime
          : note.startEpochTimeStamp - recordingStartTime - timeObj.pauseTime;
    }

    return {
      ...note,
      videoTime,
    };
  };

  return notes.map(calculateNoteTime);
};
