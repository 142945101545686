import classnames from 'classnames';
import React, { useRef } from 'react';
import styles from './Icon.scss';

export interface IIconProps {
  active: boolean;
  icon: string;
  onClick: (evt: React.MouseEvent) => void;
  animateText?: string;
  onBlur?: () => void;
  tooltip?: string;
  tooltipPosition?: string;
}

const getTextWidth = (text: string) => {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const metrics = context.measureText(text);
  return metrics.width;
};

const Icon: React.SFC<IIconProps> = (props) => {
  const buttonRef = useRef(null);
  const onMouseDown = (e) => {
    e.preventDefault();
    buttonRef.current.focus();
    props.onClick(e);
  };

  return (
    <button
      className={classnames(
        styles.iconBtn,
        props.active && styles.iconBtnActive
      )}
      onMouseDown={onMouseDown}
      data-notes-tip={props.tooltip}
      data-notes-tip-at={props.tooltipPosition}
      onBlur={props.onBlur}
      ref={buttonRef}
    >
      <i
        className={classnames(
          styles.icon,
          `togo-icon ${props.icon}`,
          props.active && styles.iconActive
        )}
      />
      {props.animateText && (
        <span
          className={classnames(
            styles.iconText,
            props.active && styles.iconTextActive
          )}
          style={{
            width: props.active ? getTextWidth(props.animateText) : '0',
          }}
        >
          {props.animateText}
        </span>
      )}
    </button>
  );
};

export default Icon;
