exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".shareable-ui-src-components-Icon-Icon--iconBtn {\n  -ms-flex-align: center;\n      align-items: center;\n  background-color: white;\n  border: 0;\n  border-bottom: 1px solid #d7d7d7;\n  border-top: 1px solid #d7d7d7;\n  border-left: 1px solid #d7d7d7;\n  cursor: pointer;\n  display: -ms-flexbox;\n  display: flex;\n  font-size: 12px;\n  -ms-flex-pack: start;\n      justify-content: flex-start;\n  padding: 2px;\n  width: auto; }\n  .shareable-ui-src-components-Icon-Icon--iconBtn:hover .shareable-ui-src-components-Icon-Icon--icon {\n    color: #d7d7d7; }\n  .shareable-ui-src-components-Icon-Icon--iconBtn:first-child {\n    border-left: 1px solid #d7d7d7;\n    border-top-left-radius: 5px;\n    border-bottom-left-radius: 5px;\n    padding-left: 6px; }\n  .shareable-ui-src-components-Icon-Icon--iconBtn:last-child {\n    border-right: 1px solid #d7d7d7;\n    border-top-right-radius: 5px;\n    border-bottom-right-radius: 5px;\n    padding-right: 6px; }\n  .shareable-ui-src-components-Icon-Icon--iconBtn.shareable-ui-src-components-Icon-Icon--iconBtnActive {\n    background-color: #D14D5A;\n    border: 1px solid #D14D5A; }\n  .shareable-ui-src-components-Icon-Icon--iconBtn .shareable-ui-src-components-Icon-Icon--icon {\n    display: -ms-flexbox;\n    display: flex;\n    color: #bebebe;\n    font-size: 1.4em;\n    margin: 0 2px; }\n  .shareable-ui-src-components-Icon-Icon--iconBtn .shareable-ui-src-components-Icon-Icon--icon.shareable-ui-src-components-Icon-Icon--iconActive {\n      color: white; }\n  .shareable-ui-src-components-Icon-Icon--iconBtn .shareable-ui-src-components-Icon-Icon--iconText {\n    color: white;\n    visibility: hidden;\n    transition: width 200ms ease-in-out; }\n  .shareable-ui-src-components-Icon-Icon--iconBtn .shareable-ui-src-components-Icon-Icon--iconText.shareable-ui-src-components-Icon-Icon--iconTextActive {\n      visibility: visible;\n      margin-right: 4px; }\n", ""]);

// Exports
exports.locals = {
	"iconBtn": "shareable-ui-src-components-Icon-Icon--iconBtn",
	"icon": "shareable-ui-src-components-Icon-Icon--icon",
	"iconBtnActive": "shareable-ui-src-components-Icon-Icon--iconBtnActive",
	"iconActive": "shareable-ui-src-components-Icon-Icon--iconActive",
	"iconText": "shareable-ui-src-components-Icon-Icon--iconText",
	"iconTextActive": "shareable-ui-src-components-Icon-Icon--iconTextActive"
};