import clsx from 'clsx';
import { EditorState } from 'draft-js';
import React from 'react';
// import ReactTooltip from 'react-tooltip';
import createInlineToolbarPlugin from '../../plugins/inlineToolbar';
import linkPlugin from '../../plugins/linkPlugin';
import styles from '../../styles/toolbar.scss';
import ActionItem from './Buttons/actionItem';
import BoldButton from './Buttons/bold';
import Decision from './Buttons/decision';
import Highlight from './Buttons/highLight';
import ItalicButton from './Buttons/italic';
import OrderedListButton from './Buttons/orderedList';
import ShowMoreButton from './Buttons/showMoreButton';
import Strikethrough from './Buttons/strikeThrough';
import UnorderedListButton from './Buttons/unOrderedList';

export const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

interface IProps {
  toolConfig?: string[];
  editorState: EditorState;
  scrollingEleId?: string;
  errorMessage: string;
  onChange(editorState: EditorState): void;
  onToolUsed(event: any): void;
  linkBlurEvent?(status: boolean);
}

export default class InlineToolbarComponent extends React.Component<IProps> {
  state = {
    responsiveToolbar: false,
  };

  showHideToolbar = () => {
    this.setState({
      responsiveToolbar: !this.state.responsiveToolbar,
    });
  };

  render() {
    const menuItem = styles['menu-item'];
    const bold = styles['menu-item-bold'];
    const italic = styles['menu-item-italic'];
    const strike = styles['menu-item-strike'];
    const link = styles['menu-item-link'];
    const unOrderedListing = styles['menu-item-unorder'];
    const orderedListing = styles['menu-item-order'];
    const highLightHashTag = styles['menu-item-highlight'];
    const actionItemHashTag = styles['menu-item-action'];
    const decisionTHashTag = styles['menu-item-decision'];
    const toolBarContainer = styles['toolbar-container'];
    const secondaryToolbar = styles['secondary-toolbar'];
    const secondaryToolBarFirstChild = styles['secondary-toolbar-first-child'];
    const collapsedMenuItem = styles['collapsed-menu-item'];
    const collapsedBold = styles['collapsed-menu-item-bold'];
    const collapsedItalic = styles['collapsed-menu-item-italic'];
    const collapsedStrike = styles['collapsed-menu-item-strike'];
    const collapsedLink = styles['collapsed-menu-item-link'];
    const collapsedUnOrderedListing = styles['collapsed-menu-item-unorder'];
    const collapsedOrderedListing = styles['collapsed-menu-item-order'];
    const secondaryToolBarSecondChild =
      styles['secondary-toolbar-second-child'];
    const collapsedHighlight = styles['collapsed-menu-item-highlight'];
    const collapsedActionItem = styles['collapsed-menu-item-action'];
    const collapsedDecision = styles['collapsed-menu-item-decision'];

    return (
      <InlineToolbar
        onToolUsed={this.props.onToolUsed}
        scrollingEleId={this.props.scrollingEleId}
        linkBlurEvent={this.props.linkBlurEvent}
      >
        {(externalProps) => {
          return this.props.errorMessage ? (
            <div className={clsx(toolBarContainer, styles.errorMessage)}>
              {this.props.errorMessage}
            </div>
          ) : (
            <>
              {}
              <div className={toolBarContainer}>
                <div>
                  <BoldButton
                    {...externalProps}
                    className={clsx(menuItem, bold)}
                  />
                  <ItalicButton
                    {...externalProps}
                    className={clsx(menuItem, italic)}
                  />
                  <Strikethrough
                    {...externalProps}
                    className={clsx(menuItem, strike)}
                  />
                  <linkPlugin.LinkButton
                    {...externalProps}
                    className={clsx(menuItem, link)}
                  />
                  <UnorderedListButton
                    {...externalProps}
                    className={clsx(menuItem, unOrderedListing)}
                  />
                  <OrderedListButton
                    {...externalProps}
                    className={clsx(menuItem, orderedListing)}
                  />
                  <div
                    className={styles.showMoreButton}
                    onClick={this.showHideToolbar}
                  >
                    <ShowMoreButton
                      {...externalProps}
                      className={styles['menu-item']}
                    />
                  </div>
                </div>
                <div>
                  <Highlight
                    {...externalProps}
                    className={clsx(menuItem, highLightHashTag)}
                  />
                  <ActionItem
                    {...externalProps}
                    className={clsx(menuItem, actionItemHashTag)}
                  />
                  <Decision
                    {...externalProps}
                    className={clsx(menuItem, decisionTHashTag)}
                  />
                </div>
              </div>
              {this.state.responsiveToolbar && (
                <div className={clsx(toolBarContainer, secondaryToolbar)}>
                  <div className={secondaryToolBarFirstChild}>
                    <BoldButton
                      {...externalProps}
                      className={clsx(collapsedMenuItem, collapsedBold)}
                    />
                    <ItalicButton
                      {...externalProps}
                      className={clsx(collapsedMenuItem, collapsedItalic)}
                    />
                    <Strikethrough
                      {...externalProps}
                      className={clsx(collapsedMenuItem, collapsedStrike)}
                    />
                    <linkPlugin.LinkButton
                      {...externalProps}
                      className={clsx(collapsedMenuItem, collapsedLink)}
                    />
                    <UnorderedListButton
                      {...externalProps}
                      className={clsx(
                        collapsedMenuItem,
                        collapsedUnOrderedListing
                      )}
                    />
                    <OrderedListButton
                      {...externalProps}
                      className={clsx(
                        collapsedMenuItem,
                        collapsedOrderedListing
                      )}
                    />
                  </div>
                  <div className={secondaryToolBarSecondChild}>
                    <Highlight
                      {...externalProps}
                      className={clsx(collapsedMenuItem, collapsedHighlight)}
                    />
                    <ActionItem
                      {...externalProps}
                      className={clsx(collapsedMenuItem, collapsedActionItem)}
                    />
                    <Decision
                      {...externalProps}
                      className={clsx(collapsedMenuItem, collapsedDecision)}
                    />
                  </div>
                </div>
              )}
            </>
          );
        }}
      </InlineToolbar>
    );
  }
}
