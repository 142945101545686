import Error from './error';
export const errorStrategy = (contentBlock, callback) => {
  const text = contentBlock.getText();
  const regex = /[<]/gi;
  let result = regex.exec(text);
  const indices = [];
  while (result) {
    indices.push(result.index);
    result = regex.exec(text);
  }
  indices.map((index) => {
    callback(index, index + 1);
  });
};

const characterNotAllowed = () => {
  return {
    decorators: [
      {
        strategy: errorStrategy,
        component: Error,
      },
    ],
  };
};

const specialCharactersErrorPlugin = characterNotAllowed();
export default specialCharactersErrorPlugin;
