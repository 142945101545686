import {
  createAction,
  createAsyncAction,
} from 'redux-promise-middleware-actions';
import * as api from '../../api';
import { INotesEntryProps } from '../../NotesEntry';

export const addVideoCorelation = createAction(
  'ADD_NOTE_VIDEO_CORRELATION',
  (recordingEpochTimes: INotesEntryProps['recordingEpochTimes']) =>
    recordingEpochTimes
);
export const clearCreateError = createAction('CLEAR_CREATE_ERROR');
export const playNote = createAction('PLAY_NOTE', (videoTime) => ({
  videoTime,
}));
export const acceptNote = createAsyncAction('ACCEPT_NOTE', api.acceptNote);
export const editNote = createAsyncAction('EDIT_NOTE', api.editNote);
export const denyNote = createAsyncAction('DENY_NOTE', api.deleteNote);
export const createNote = createAsyncAction('CREATE_NOTE', api.createNote);
export const deleteNote = createAsyncAction('DELETE_NOTE', api.deleteNote);
export const getNotes = createAsyncAction('GET_NOTES', api.getNotes);
