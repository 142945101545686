import React from 'react';
import ReactTooltip from 'react-tooltip';
import { TranslationContext } from '../../../context/TranslationsContext';
import createInlineStyleButton from '../../HOC/createInlineButtonComponent';
import styles from './styles/button.scss';

class Italic extends React.Component {
  static contextType = TranslationContext;

  render() {
    return (
      <>
        <span
          className={styles['italic-icon-container']}
          data-tip={this.context.italic}
          data-offset="{'top': 10}"
          data-for="italicOverlay"
          data-effect="solid"
        >
          I
        </span>
        <ReactTooltip id="italicOverlay" />
      </>
    );
  }
}
export default createInlineStyleButton('ITALIC', <Italic />);
