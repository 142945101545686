import React from 'react';
import { translations } from '../translations';

export interface ITranslationsProps {
  notePlaceHolderText: string;
  noteError: string;
  spcialCharacterErrorMsg: string;
  bold: string;
  orderList: string;
  italic: string;
  strikethrough: string;
  unOrderList: string;
  link: string;
  highlight: string;
  actionItem: string;
  decision: string;
}
const defaultTranslation = translations.en;
export const TranslationContext = React.createContext<ITranslationsProps>(
  defaultTranslation
);
