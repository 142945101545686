exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".shareable-ui-src-components-Notes-Notes--notes {\n  display: block;\n  margin: 0 auto;\n  max-width: 700px;\n  padding: 0 6px 5px;\n  position: relative;\n  width: 100%; }\n  .shareable-ui-src-components-Notes-Notes--notes .react-hint {\n    pointer-events: none; }\n  .shareable-ui-src-components-Notes-Notes--notes .shareable-ui-src-components-Notes-Notes--fadeEnter {\n    opacity: 1; }\n  .shareable-ui-src-components-Notes-Notes--notes .shareable-ui-src-components-Notes-Notes--fadeEnterActive {\n    opacity: 1;\n    transition: opacity 250ms ease-out; }\n  .shareable-ui-src-components-Notes-Notes--notes .shareable-ui-src-components-Notes-Notes--fadeExit {\n    opacity: 1; }\n  .shareable-ui-src-components-Notes-Notes--notes .shareable-ui-src-components-Notes-Notes--fadeExitActive {\n    opacity: 0;\n    transition: opacity 250ms ease-out; }\n  .shareable-ui-src-components-Notes-Notes--noteList {\n  padding-left: 0; }\n", ""]);

// Exports
exports.locals = {
	"notes": "shareable-ui-src-components-Notes-Notes--notes",
	"fadeEnter": "shareable-ui-src-components-Notes-Notes--fadeEnter",
	"fadeEnterActive": "shareable-ui-src-components-Notes-Notes--fadeEnterActive",
	"fadeExit": "shareable-ui-src-components-Notes-Notes--fadeExit",
	"fadeExitActive": "shareable-ui-src-components-Notes-Notes--fadeExitActive",
	"noteList": "shareable-ui-src-components-Notes-Notes--noteList"
};