import * as React from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { ICommonApiParams } from './api';
import { INote } from './api/INotesResponse';
import NotesContainer from './containers/Notes/NotesContainer';
import middleware from './reducks/middleware/middleware';
import rootReducer from './reducks/rootReducer';

export interface INotesEntryProps {
  /**
   * Auth token for your product session, we use this for making authenticated API calls
   */
  authToken?: string;
  /**
   * The base url for the API calls. Any urls from the base urls here will work
   * https://bitbucket.ops.expertcity.com/projects/GPP/repos/infrastructure-properties/browse/apiproxy.properties#1-5
   */
  baseUrl: string;
  /**
   * Filters is an array of INote types or a 'bookmark'. The array contents will be the only notes that will show.
   */
  filters?: Array<INote['type'] | 'bookmark'>;
  /**
   * A ivsToken is a token gotten from Identity Verification Service. More docs can be found here
   * https://confluence.ops.expertcity.com/display/SOA/Identity+Verification+Service+API
   */
  ivsToken?: ICommonApiParams['ivsToken'];
  /**
   * A jwtToken requested from the notes service. This is used in liue of an authToken.
   */
  jwtToken?: string;
  /**
   * A string that can have the type of "'insession' | 'postsession' | 'autogenerated'"
   * We only support "'insession' | 'postsession'" and only 'insession' will show the create note UI
   */
  origin: ICommonApiParams['origin'];
  /**
   * A short form string for a product. Ex. g2m, g2c, g2w, etc.
   */
  product: string;
  /**
   * A string for a specific key correlated to the notes service. In G2M's case the referenceKey
   * will be the G2M sessionKey.
   */
  referenceKey?: string;
  /**
   * An array of recording start and stop times. The notes are supposed to be relative to these times.
   * The note times will get an additionaly property called videoTime which will allow the user to
   * skip to the part of the video that they'd like
   */
  recordingEpochTimes?: Array<{ startTime: number; stopTime: number }>;
  /**
   * A string representanting a shareKey from sharingService. Currently only used by G2M.
   */
  shareKey?: string;
  /**
   * Filter for showing or hiding autogenerated notes.
   */
  showAutogenerated?: boolean;
  /**
   * A string representing a LogMeIn userKey as found in accountService.
   */
  userKey?: string;
}

let store;

export default class NotesEntry extends React.PureComponent<INotesEntryProps> {
  render() {
    return (
      <ReactReduxContext.Consumer>
        {(context) => {
          if (context && context.store && context.store.getState().notes) {
            return <NotesContainer {...this.props} />;
          }

          if (!store) {
            store = createStore(rootReducer, applyMiddleware(...middleware));
          }

          return (
            <Provider store={store}>
              <NotesContainer {...this.props} />
            </Provider>
          );
        }}
      </ReactReduxContext.Consumer>
    );
  }
}
