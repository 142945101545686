import { INote } from '../APIs/INotesResponse';

interface IChangeDetectionNoteType {
  text: string;
  notesStyles?: INote['notesStyles'];
  notesBlocks?: INote['notesBlocks'];
}

export const isNoteChanged = (
  oldNote: IChangeDetectionNoteType,
  changedNote: IChangeDetectionNoteType
): boolean => {
  if (oldNote.text !== changedNote.text) {
    return true;
  }

  if (oldNote.notesBlocks.length !== changedNote.notesBlocks.length) {
    return true;
  }

  if (oldNote.notesStyles.length !== changedNote.notesStyles.length) {
    return true;
  }

  const changedNoteBlock = oldNote.notesBlocks.find((note, index) => {
    return note.style !== changedNote.notesBlocks[index].style;
  });

  const changedNoteStyle = oldNote.notesStyles.find((style, index) => {
    const changedStyle = changedNote.notesStyles[index];
    return (
      style.style !== changedStyle.style ||
      style.offset !== changedStyle.offset ||
      style.length !== changedStyle.length
    );
  });

  if (changedNoteBlock || changedNoteStyle) {
    return true;
  }

  return false;
};

export const isSpecialCharacterPresent = (notesText: string) => {
  return notesText.indexOf('<') > -1;
};
