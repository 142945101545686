import {
  ContentState,
  convertToRaw,
  EditorState,
  RawDraftContentBlock,
} from 'draft-js';
import React from 'react';
import {
  createNote,
  deleteNote,
  editNote,
  ICommonApiParams,
} from '../APIs/index';
import { INote } from '../APIs/INotesResponse';
import MixPanelEvents from '../APIs/MixPanelEvents';
import EditorComponent from '../components/Editor';
import ErrorBoundary from '../components/EditorErrorBoundary';
import {
  NOTECREATED,
  NOTECREATEDERROR,
  NOTEDELETE,
  NOTEDELETEERROR,
  NOTEEDIT,
  NOTEEDITERROR,
  NOTESAPPVISITED,
} from '../constants';
import { TranslationContext } from '../context/TranslationsContext';
import {
  backendStateToDraftJsFormat,
  draftStateToBackendState,
  mergeNotesToDraftjsState,
} from '../converter/backendDraftJSbackendAdapter';
import { translations } from '../translations';
import {
  isNoteChanged,
  isSpecialCharacterPresent,
} from '../utitlity/notesUtility';

interface IProps {
  authToken?: string;
  baseUrl?: string;
  jwtToken?: string;
  locale: string;
  product?: string;
  referenceKey?: string;
  userKey?: string;
  origin?: INote['origin'];
  notes?: INoteObjectType[];
  logger?: {
    error(name: string, error: any);
  };
  mixPanelProductName?: string;
  mixPanelTrack?(name: string): void;
}

export interface INoteObjectType extends Partial<INote> {
  text: string;
  type?: INote['type'];
  notesStyles?: INote['notesStyles'];
  notesBlocks?: INote['notesBlocks'];
  id?: string;
  multiNoteType?: Array<INote['type']>;
  videoTime?: INote['videoTime'];
}
interface INotesMap {
  [key: string]: Partial<INoteObjectType> & {
    editorState?: EditorState;
    isCreateNotePending?: boolean;
  };
}
class EditorComponentWrapper extends React.Component<IProps> {
  apiCommonParams: ICommonApiParams = {
    authToken: '',
    baseUrl: '',
    origin: 'insession',
    product: '',
    jwtToken: '',
    referenceKey: '',
    userKey: '',
  };
  noteKeyMap = {};
  notesBlocksList = [];
  mixPanelEvent: MixPanelEvents;
  locale: any;
  notesMap: INotesMap = {};

  constructor(props: IProps) {
    super(props);

    this.apiCommonParams = {
      authToken: props.authToken,
      baseUrl: props.baseUrl,
      origin: props.origin,
      product: props.product,
      jwtToken: props.jwtToken,
      referenceKey: props.referenceKey,
      userKey: props.userKey,
    };

    this.mixPanelEvent = new MixPanelEvents(props.mixPanelTrack, {
      origin: props.origin,
      product: props.mixPanelProductName,
    });
    this.locale = (props.locale && props.locale.split('_')[0]) || 'en';
    this.mixPanelEvent.trackEvents(NOTESAPPVISITED);
    this.buildDraftFormatFromNote();
  }

  buildDraftFormatFromNote = () => {
    if (this.props.notes) {
      this.props.notes.forEach((note: INoteObjectType) => {
        const { text, notesBlocks, notesStyles, id, type } = note;
        const draftJSFormattedBlocks = backendStateToDraftJsFormat({
          text,
          notesBlocks,
          notesStyles,
          addNotesDividerBlock: true,
        });
        this.noteKeyMap[draftJSFormattedBlocks.blocks[0].key] = true;
        const notesKey =
          draftJSFormattedBlocks.blocks[0] &&
          draftJSFormattedBlocks.blocks[0].key;
        this.notesMap.key = notesKey;
        const obj = {
          type,
          notesStyles,
          notesBlocks,
          text,
          id,
          editorState: EditorState.createEmpty(),
        };
        this.notesMap[notesKey] = obj;
        this.notesBlocksList.push(draftJSFormattedBlocks);
      });
    }
  };

  syncNotes = async (
    key: string,
    note: RawDraftContentBlock[],
    editorState: EditorState,
    contentState: ContentState
  ) => {
    const backendFormatedNote: INoteObjectType = draftStateToBackendState(
      convertToRaw(contentState),
      note
    );
    const {
      text,
      notesStyles,
      notesBlocks,
      multiNoteType,
      type,
    } = backendFormatedNote;
    const isNoteCreated = this.notesMap[key];

    if (text.length < 1 || isSpecialCharacterPresent(text)) {
      return;
    }

    try {
      const syncNotePromise = isNoteCreated
        ? await this.editNote(
            key,
            text,
            notesStyles,
            notesBlocks,
            multiNoteType,
            type,
            backendFormatedNote
          )
        : await this.createNote(
            key,
            text,
            notesStyles,
            notesBlocks,
            multiNoteType
          );
      if (syncNotePromise.sync === false) {
        return;
      }

      const eventName =
        this.notesMap[key] && this.notesMap[key].id ? NOTEEDIT : NOTECREATED;
      this.notesMap[key] = {
        ...backendFormatedNote,
        id: syncNotePromise.id,
        editorState,
        isCreateNotePending: false,
      };
      this.mixPanelEvent.trackeNoteCreateAndEditEvents(
        eventName,
        syncNotePromise.id,
        notesStyles,
        multiNoteType
      );
    } catch (e) {
      const errorMsgKey =
        this.notesMap[key] && this.notesMap[key].id
          ? NOTEEDITERROR
          : NOTECREATEDERROR;
      this.props.logger.error(errorMsgKey, e);
    }
  };

  editNote = async (
    key,
    text,
    notesStyles,
    notesBlocks,
    multiNoteType,
    type,
    backendFormatedNote
  ) => {
    if (
      !this.notesMap[key].id ||
      !isNoteChanged(
        {
          text: this.notesMap[key].text,
          notesBlocks: this.notesMap[key].notesBlocks,
          notesStyles: this.notesMap[key].notesStyles,
        },
        backendFormatedNote
      )
    ) {
      return Promise.resolve({
        sync: false,
      });
    }

    return editNote(
      this.notesMap[key].id,
      text,
      notesStyles,
      notesBlocks,
      multiNoteType,
      type,
      this.apiCommonParams
    );
  };

  createNote = async (key, text, notesStyles, notesBlocks, multiNoteType) => {
    this.notesMap[key] = { isCreateNotePending: true };

    return createNote(
      text,
      notesStyles,
      notesBlocks,
      multiNoteType,
      this.apiCommonParams
    );
  };

  deleteNote = async (noteKey: string, editorState: EditorState) => {
    const id = (this.notesMap[noteKey] && this.notesMap[noteKey].id) || null;
    if (id === null) {
      return;
    }
    try {
      delete this.notesMap[noteKey];
      await deleteNote(id, this.apiCommonParams);
      this.mixPanelEvent.trackEvents(NOTEDELETE, { id });
    } catch (e) {
      this.props.logger.error(NOTEDELETEERROR, e);
    }
  };

  render() {
    return (
      <ErrorBoundary logger={this.props.logger}>
        <TranslationContext.Provider value={translations[this.locale]}>
          <EditorComponent
            syncNotes={this.syncNotes}
            deleteNote={this.deleteNote}
            initialState={mergeNotesToDraftjsState(this.notesBlocksList)}
            notesMap={this.noteKeyMap}
            mixPanelTrack={this.mixPanelEvent.trackEvents.bind(
              this.mixPanelEvent
            )}
          />
        </TranslationContext.Provider>
      </ErrorBoundary>
    );
  }
}

export default EditorComponentWrapper;
