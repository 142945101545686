import React, { CSSProperties } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { INotification } from '../../reducks/ui/reducer';
import styles from './Notification.scss';

const genericStyles: { [key in INotification['type']]: CSSProperties } = {
  error: {
    backgroundColor: '#fee2e8',
    color: '#ff0000',
  },
  success: {
    backgroundColor: '#e0fcf1',
    color: '#4aa26d',
  },
};

export interface INotifcationProps {
  notification: INotification;
}

const Notifcation: React.SFC<INotifcationProps> = ({ notification }) => (
  <TransitionGroup>
    <CSSTransition
      key={Math.random()}
      classNames={{
        enterActive: styles.scaleAndFade,
      }}
      in={!!notification.message}
      timeout={notification.timeout}
    >
      <div
        className={styles.notification}
        style={genericStyles[notification.type]}
      >
        {notification.icon && <i className={notification.icon} />}
        {notification.message}
      </div>
    </CSSTransition>
  </TransitionGroup>
);

export default Notifcation;
