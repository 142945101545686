import classnames from 'classnames';
import React from 'react';
import styles from './NoteInputError.scss';

interface INoteInputErrorProps {
  className?: string;
}

const errorText =
  'The following characters can be used with SmartNotes: # % @ ? _ - [ ] ( ) & \' " ! : , . 0123456789 and all letters A–Z (upper and lower case).';

export const NoteInputError: React.FC<INoteInputErrorProps> = (props) => {
  return (
    <div className={classnames(props.className, styles.inputError)}>
      <span>{errorText}</span>
      <div className={styles.stubContainer}>
        <div className={styles.stub} />
      </div>
    </div>
  );
};
