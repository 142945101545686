import { EditorState, getVisibleSelectionRect } from 'draft-js';
import React, { ReactElement } from 'react';
import styles from '../../styles/toolbar.scss';
import { getCurrentBlock } from '../../utitlity/draftUtility';
interface IProps {
  store: any;
  theme: any;
  scrollingEleId?: string;
  children(arg: any): ReactElement;
  onToolUsed(event: any): void;
  linkBlurEvent?(status: boolean);
}

interface IState {
  cssStyles: {
    [key: string]: string | number;
  };
  isVisible: boolean;
  overrideContent: undefined | any;
  focus: boolean;
}
export default class Toolbar extends React.Component<IProps, IState> {
  lastKeyPressedDelete = false;
  toolbar: any;
  selectionChange = false;
  blur = false;
  editorContainer = null;
  viewPortClass: string = null;
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      cssStyles: undefined,
      overrideContent: undefined,
      focus: false,
    };

    if (props.scrollingEleId) {
      this.editorContainer = document.querySelector(`#${props.scrollingEleId}`);
    }
  }
  onFocusHandler = () => {
    this.setState({
      focus: true,
    });
  };

  onBlurHandler = () => {
    this.selectionChange = false;
    this.blur = true;
    this.setState({
      focus: false,
    });
  };

  onDeleteHandler = () => {
    this.lastKeyPressedDelete = true;
  };

  UNSAFE_componentWillMount() {
    this.props.store.subscribeToItem('selection', this.onSelectionChanged);
    this.props.store.subscribeToItem('focus', this.onFocusHandler);
    this.props.store.subscribeToItem('blur', this.onBlurHandler);
    if (this.editorContainer) {
      this.editorContainer.addEventListener('scroll', this.onBlurHandler);
    }
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    this.props.store.unsubscribeFromItem('selection', this.onSelectionChanged);
    this.props.store.unsubscribeFromItem('focus', this.onFocusHandler);
    this.props.store.unsubscribeFromItem('blur', this.onBlurHandler);
    if (this.editorContainer) {
      this.editorContainer.removeEventListener('scroll', this.onBlurHandler);
    }
    window.removeEventListener('resize', this.resizeHandler);
  }

  resizeHandler = () => {
    this.setState({
      focus: false,
    });

    this.setToolbarWidth(true);
  };

  setToolbarWidth = (forceNewWidth = false) => {
    const editorRef = this.props.store.getItem('getEditorRef')();
    if (!forceNewWidth && (editorRef === null || this.viewPortClass !== null)) {
      return;
    }

    let editorRoot =
      editorRef.refs && editorRef.refs.editor
        ? editorRef.refs.editor
        : editorRef.editor;
    while (editorRoot.className.indexOf('DraftEditor-root') === -1) {
      editorRoot = editorRoot.parentNode;
    }
    const editorRootRect = editorRoot.getBoundingClientRect();
    let className = '';
    if (editorRootRect.width >= 480 && editorRootRect.width < 560) {
      className = styles.xlg;
    } else if (editorRootRect.width >= 420 && editorRootRect.width < 480) {
      className = styles.lg;
    } else if (editorRootRect.width >= 360 && editorRootRect.width < 420) {
      className = styles.md;
    } else if (editorRootRect.width >= 320 && editorRootRect.width < 360) {
      className = styles.sm;
    } else if (editorRootRect.width < 319) {
      className = styles.xsm;
    }

    this.viewPortClass = className;
  };

  isMultiBlockSelected = () => {
    const editorState: EditorState = this.props.store.getItem(
      'getEditorState'
    )();

    if (editorState) {
      const selection = editorState.getSelection();
      if (selection.getStartKey() !== selection.getEndKey()) {
        return true;
      }
    }

    return false;
  };

  /**
   * This can be called by a child in order to render custom content instead
   * of the children prop. It's the responsibility of the callee to call
   * this function again with `undefined` in order to reset `overrideContent`.
   * @param {Component} overrideContent
   */
  onOverrideContent = (overrideContent) => {
    // on decorating text with link blur event occurs but we need to show even after it.
    const focus = typeof overrideContent === 'undefined';
    this.props.linkBlurEvent && this.props.linkBlurEvent(true);
    this.setState({ overrideContent, focus });
  };

  onSelectionChanged = () => {
    this.selectionChange = !this.blur;
    setTimeout(() => {
      if (!this.toolbar) {
        return;
      }
      const { scrollingEleId } = this.props;
      const editorRef = this.props.store.getItem('getEditorRef')();
      if (!editorRef) {
        return;
      }

      let editorRoot =
        editorRef.refs && editorRef.refs.editor
          ? editorRef.refs.editor
          : editorRef.editor;
      while (editorRoot.className.indexOf('DraftEditor-root') === -1) {
        editorRoot = editorRoot.parentNode;
      }
      const editorRootRect = editorRoot.getBoundingClientRect();

      const selectionRect = getVisibleSelectionRect(window);

      if (!selectionRect) {
        return;
      }
      this.setToolbarWidth();
      const extraTopOffset = this.isMultiBlockSelected() ? -40 : 16;
      const scrollWindow = 46;

      // this is for moving toolbar close to content
      let topOffset =
        editorRoot.offsetTop +
        (selectionRect.top - editorRootRect.top) +
        extraTopOffset;

      // scrolling page by some pixel once content reaches at end of the viewport
      // replace window innerheight with wrapper component view
      const viewPortheight = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );

      let scrollEle;
      let scrollY;
      let parentEle = null;
      let totalWindowHeight;

      if (this.editorContainer) {
        parentEle = this.editorContainer; // parentEleRef or id
        const parentTopOffset =
          scrollingEleId && parentEle.getBoundingClientRect().top;
        scrollY = parentEle.scrollTop;
        totalWindowHeight =
          viewPortheight - scrollWindow + scrollY - parentTopOffset + 10;
        scrollEle = parentEle;
        topOffset = topOffset - scrollY;
      } else {
        scrollEle = window;
        scrollY = window.scrollY;
        totalWindowHeight = viewPortheight + scrollY - scrollWindow;
      }

      if (topOffset > totalWindowHeight) {
        scrollEle.scrollBy(0, scrollWindow);
      }

      const cssStyles = {
        top: topOffset,
        left: editorRoot.offsetLeft - 5,
      };
      const stateUpdate: {
        cssStyles: IState['cssStyles'];
        focus: boolean;
      } = { cssStyles, focus: this.state.focus };

      if (this.editorContainer) {
        stateUpdate.focus = true;
      }

      this.setState(stateUpdate);
    });
  };

  getStyle = (notEmptyBlock: boolean) => {
    const { cssStyles, focus } = this.state;
    const style = { ...cssStyles };

    if (
      notEmptyBlock &&
      (focus || this.selectionChange || this.state.overrideContent)
    ) {
      style.visibility = 'visible';
      style.transform = 'translate(0) scale(1)';
      style.transition = 'transform 0.15s cubic-bezier(.3,1.2,.2,1)';
    } else {
      style.transform = 'translate(-50%) scale(0)';
      style.visibility = 'hidden';
    }

    return style;
  };

  handleToolbarRef = (node) => {
    this.toolbar = node;
  };

  isBlockEmpty = (editorState: EditorState): boolean => {
    const contentBlock = getCurrentBlock(editorState);
    const text = contentBlock.getText();
    return text.length === 0;
  };
  render() {
    const { theme, store } = this.props;
    const { overrideContent: OverrideContent } = this.state;

    const childrenProps = {
      theme: theme.buttonStyles,
      getEditorState: store.getItem('getEditorState'),
      setEditorState: store.getItem('setEditorState'),
      getEditorRef: store.getItem('getEditorRef'),
      onOverrideContent: this.onOverrideContent,
      onToolSelected: this.props.onToolUsed,
    };

    return (
      <div
        className={`${theme.toolbarStyles.toolbar} ${this.viewPortClass}`}
        style={this.getStyle(
          !this.isBlockEmpty(childrenProps.getEditorState())
        )}
        ref={this.handleToolbarRef}
      >
        {OverrideContent ? (
          <OverrideContent {...childrenProps} />
        ) : (
          this.props.children(childrenProps)
        )}
      </div>
    );
  }
}
