import * as React from 'react';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { INote } from '../../api/INotesResponse';
import NotificationConatiner from '../../containers/Notification/NotificationContainer';
import { INotesEntryProps } from '../../NotesEntry';
import { INotes } from '../../reducks/notes/reducer';
import { scrollToBottomElement } from '../../utils/smoothscroll';
import Note from './Note/Note';
import NoteInput from './NoteInput/NoteInput';
import styles from './Notes.scss';

const ReactHint = ReactHintFactory(React);

export interface INotesProps {
  acceptNote: (id: INote['id']) => void;
  addVideoCorelation: (
    recordingEpochTimes: INotesEntryProps['recordingEpochTimes']
  ) => void;
  clearCreateError: () => void;
  clearDeleteError: (id: INote['id']) => void;
  createNote: (text: INote['text'], type: INote['type']) => void;
  deleteNote: (id: INote['id']) => void;
  denyNote: (id: INote['id']) => void;
  editNote: (id: INote['id'], text: INote['text'], type: INote['type']) => void;
  playNote: (videoTime: INote['videoTime']) => void;
  getNotes: () => void;
  isOwner: boolean;
  notes: INotes;
  origin: INote['origin'];
  recordingEpochTimes?: INotesEntryProps['recordingEpochTimes'];
}

export default class Notes extends React.PureComponent<INotesProps> {
  baseContainer = React.createRef<HTMLDivElement>();

  state = {
    activeNoteId: '',
    activeEditNoteId: '',
  };

  componentDidMount() {
    const {
      addVideoCorelation,
      getNotes,
      notes,
      recordingEpochTimes,
    } = this.props;

    if (typeof notes.pending !== 'boolean') {
      getNotes();
    }

    if (!notes.recordingEpochTimes && recordingEpochTimes) {
      addVideoCorelation(recordingEpochTimes);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notes.createPending && !this.props.notes.createPending) {
      scrollToBottomElement(this.baseContainer.current);
    }
  }

  setActiveNoteId = (id?: string) => {
    this.setState({ activeNoteId: id || '' });
  };

  setActiveEditNoteId = (id?: string) => {
    this.setState({ activeEditNoteId: id || '' });
  };

  render() {
    const { activeNoteId } = this.state;
    const {
      acceptErrorId,
      data: notes,
      createPending,
      createError,
      deleteErrorId,
      editErrorId,
    } = this.props.notes;
    const {
      acceptNote,
      clearCreateError,
      createNote,
      deleteNote,
      denyNote,
      editNote,
      isOwner,
      origin,
      playNote,
    } = this.props;

    return (
      <div className={styles.notes} ref={this.baseContainer}>
        {origin === 'insession' && <NotificationConatiner />}
        <ReactHint
          className={styles.tip}
          attribute="data-notes-tip"
          autoPosition
          events
        />
        <TransitionGroup className={styles.noteList} component="ul">
          {notes.map((note, i) => (
            <CSSTransition
              key={note.id}
              classNames={{
                enter: styles.fadeEnter,
                enterActive: styles.fadeEnterActive,
                exit: styles.fadeExit,
                exitActive: styles.fadeExitActive,
              }}
              timeout={300}
            >
              <Note
                acceptError={acceptErrorId === note.id}
                acceptNote={acceptNote}
                deleteError={deleteErrorId === note.id}
                deleteNote={deleteNote}
                denyNote={denyNote}
                editNote={editNote}
                isActive={activeNoteId === note.id}
                isOwner={isOwner}
                note={note}
                origin={origin}
                playNote={playNote}
                setActiveNoteId={this.setActiveNoteId}
                setActiveEditNoteId={this.setActiveEditNoteId}
                editError={editErrorId === note.id}
                tooltipLocation={i === 0 ? 'bottom' : 'top'}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
        {isOwner && (
          <NoteInput
            clearSubmitError={clearCreateError}
            submitPending={createPending}
            submitError={createError}
            submit={createNote}
            mode="create"
            origin={origin}
          />
        )}
      </div>
    );
  }
}
