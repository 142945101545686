import { createSelector } from 'reselect';
import { INote } from '../../api/INotesResponse';
import { RootState } from '../../reducks/rootReducer';

const getReferenceKey = (state, { referenceKey }) => referenceKey;
const getAuthToken = (state, { authToken }) => authToken;
const getJwtToken = (state, { jwtToken }) => jwtToken;

const applyFilters = (
  state: RootState,
  {
    filters = [],
    showAutogenerated,
  }: { filters: Array<INote['type'] | 'bookmark'>; showAutogenerated: boolean }
) => {
  let { data } = state.notes;

  if (data.length && !showAutogenerated) {
    data = data.filter(
      (note) => note.origin !== 'autogenerated' || note.status
    );
  }

  if (data.length && filters.length) {
    data = data.filter((note) => {
      const isBookmark = !note.text.replace(/\s/g, '').length;
      return (
        filters.includes(note.type) ||
        (filters.includes('bookmark') && isBookmark)
      );
    });
  }

  return { ...state.notes, data };
};

export const getFilteredNotes = createSelector(
  [applyFilters],
  (notes) => notes
);

export const isOwner = createSelector(
  [getReferenceKey, getAuthToken, getJwtToken],
  (referenceKey, authToken, jwtToken) =>
    !!(referenceKey && (authToken || jwtToken))
);
