import { Action } from 'react-redux';
import * as actions from '../notes/actions';

export interface INotification {
  icon: string;
  message: string;
  timeout: number;
  type: 'success' | 'error';
}

export interface IUI {
  notification: INotification;
}

export const uiState: IUI = {
  notification: {
    icon: '',
    message: '',
    timeout: 0,
    type: undefined,
  },
};

export default (state = uiState, action: Action): IUI => {
  switch (action.type) {
    case actions.deleteNote.fulfilled.toString():
      return {
        ...state,
        notification: {
          icon: 'togo-icon togo-icon-check',
          message: 'Deleted.',
          timeout: 2000,
          type: 'success',
        },
      };
    case actions.createNote.rejected.toString():
      return {
        ...state,
        notification: {
          icon: '',
          message:
            'Unable to create note because of a server issue. Please try again.',
          timeout: 3000,
          type: 'error',
        },
      };
    case actions.deleteNote.rejected.toString():
      return {
        ...state,
        notification: {
          icon: '',
          message:
            'Unable to delete note because of a server issue. Please try again.',
          timeout: 3000,
          type: 'error',
        },
      };
    case actions.acceptNote.rejected.toString():
      return {
        ...state,
        notification: {
          icon: '',
          message:
            'Unable to accept note because of a server issue. Please try again.',
          timeout: 3000,
          type: 'error',
        },
      };
    case actions.denyNote.rejected.toString():
      return {
        ...state,
        notification: {
          icon: '',
          message:
            'Unable to deny note because of a server issue. Please try again.',
          timeout: 3000,
          type: 'error',
        },
      };
    case actions.editNote.rejected.toString():
      return {
        ...state,
        notification: {
          icon: '',
          message:
            'Unable to edit note because of a server issue. Please try again.',
          timeout: 3000,
          type: 'error',
        },
      };
    default: {
      return state;
    }
  }
};
