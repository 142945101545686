import { convertToRaw, EditorState, RawDraftContentState } from 'draft-js';
import React from 'react';
import { createNote, editNote } from '../APIs';
import { INote } from '../APIs/INotesResponse';
import MixPanelEvents from '../APIs/MixPanelEvents';
import ErrorBoundary from '../components/EditorErrorBoundary';
import ErrorComponent from '../components/ErrorComponent/ErrorComponent';
import PostSessionEditor from '../components/PostSessionEditor';
import { NOTECOUNTLIMIT, NOTECREATED, NOTEEDIT } from '../constants';
import { TranslationContext } from '../context/TranslationsContext';
import {
  backendStateToDraftJsFormat,
  draftStateToBackendState,
} from '../converter/backendDraftJSbackendAdapter';

import { translations } from '../translations';
import { isNoteChanged } from '../utitlity/notesUtility';
import { INoteObjectType } from './InSessionEditorWrapper';

interface IPostSessionEditorWrapperProps {
  authToken?: string;
  baseUrl?: string;
  jwtToken?: string;
  product?: string;
  referenceKey?: string;
  locale: string;
  userKey?: string;
  origin?: INote['origin'];
  note?: INoteObjectType;
  readonly: boolean;
  containerId?: string;
  createNoteFlow?: boolean;
  reset?: boolean;
  className?: string;
  logger?: {
    error(name: string, error: any);
  };
  totalNotes?: number;
  mixPanelTrack?(name: string): void;
  handleNoteEdit(
    editorId: string,
    readOnly?: boolean,
    createFlow?: boolean,
    state?: 'pending' | 'success' | 'error'
  ): void;
  updateNoteReduxState?(response: any);
  createNote?(response: any);
  status?(state: 'pending' | 'success' | 'error');
}

const PostSessionEditorWrapper: React.FC<IPostSessionEditorWrapperProps> = (
  props
) => {
  const { text, notesBlocks, notesStyles, id, type } = props.note;
  let scopedText = text;
  let notesBlockList = notesBlocks || [];
  let notesStylesList = notesStyles || [];
  const locale = (props.locale && props.locale.split('_')[0]) || 'en';

  const localeText = translations[locale] || translations.en;
  const mixPanelEvent = new MixPanelEvents(props.mixPanelTrack, {
    origin: props.origin,
    product: props.product,
  });

  const {
    authToken,
    baseUrl,
    jwtToken,
    product,
    referenceKey,
    userKey,
    origin,
    updateNoteReduxState,
    createNoteFlow,
  } = props;
  const commonParams = {
    authToken,
    baseUrl,
    jwtToken,
    product,
    referenceKey,
    userKey,
    origin,
  };

  const initialState = !createNoteFlow
    ? backendStateToDraftJsFormat({
        text,
        notesBlocks: notesBlockList,
        notesStyles: notesStylesList,
        type,
      })
    : {};
  const syncNotes = async (editorState: EditorState) => {
    props.handleNoteEdit(id, true, createNoteFlow, 'pending');
    const rawContentState: RawDraftContentState = convertToRaw(
      editorState.getCurrentContent()
    );
    const backendFormatedData = draftStateToBackendState(
      rawContentState,
      rawContentState.blocks
    );

    if (
      isNoteChanged(
        {
          text: scopedText,
          notesBlocks: notesBlockList,
          notesStyles: notesStylesList,
        },
        backendFormatedData
      )
    ) {
      try {
        if (!createNoteFlow) {
          const {
            text: changedText,
            notesStyles: changedNotesStyle,
            notesBlocks: ChangedNotesBlock,
            multiNoteType,
            type: changedType,
          } = backendFormatedData;
          const response = await editNote(
            id,
            changedText,
            changedNotesStyle,
            ChangedNotesBlock,
            multiNoteType,
            changedType,
            commonParams
          );
          updateNoteReduxState(response);
          mixPanelEvent.trackeNoteCreateAndEditEvents(
            NOTEEDIT,
            response.id,
            changedNotesStyle,
            multiNoteType
          );
        } else {
          const {
            text: changedText,
            notesStyles: changedNotesStyle,
            notesBlocks: ChangedNotesBlock,
            multiNoteType,
          } = backendFormatedData;
          scopedText = changedText;
          notesBlockList = ChangedNotesBlock;
          notesStylesList = changedNotesStyle;
          const createNoteResponse = await createNote(
            changedText,
            changedNotesStyle,
            ChangedNotesBlock,
            multiNoteType,
            commonParams
          );
          mixPanelEvent.trackeNoteCreateAndEditEvents(
            NOTECREATED,
            createNoteResponse.id,
            changedNotesStyle,
            multiNoteType
          );
          props.createNote(createNoteResponse);
        }
      } catch (error) {
        props.logger.error('noteError', error);
      }
    } else {
      props.handleNoteEdit(id, true, createNoteFlow, 'success');
    }
  };
  return (
    <ErrorBoundary logger={props.logger}>
      <TranslationContext.Provider value={localeText}>
        {props.totalNotes && props.totalNotes >= NOTECOUNTLIMIT ? (
          <ErrorComponent />
        ) : (
          <PostSessionEditor
            initialState={initialState}
            id={id}
            readonly={props.readonly}
            containerId={props.containerId}
            handleNoteEdit={props.handleNoteEdit}
            syncNotes={syncNotes}
            reset={props.reset}
            createNoteFlow={props.createNoteFlow}
            className={props.className}
            mixPanelTrack={mixPanelEvent.trackEvents.bind(mixPanelEvent)}
            totalNotes={props.totalNotes}
          />
        )}
      </TranslationContext.Provider>
    </ErrorBoundary>
  );
};

export default PostSessionEditorWrapper;
