type FetchParams = Parameters<typeof fetch>;

export const fetchJson = <T>(...args: FetchParams) =>
  fetch(...args).then((res) => {
    if (!res.ok) {
      throw res;
    } else if (res.status === 204) {
      return undefined;
    }

    return res.json() as Promise<T>;
  });

export const fetchJsonWithAuth = <T>(
  url: FetchParams[0],
  jwtToken?: string,
  authToken?: string,
  options: FetchParams[1] = {}
) => {
  const headers = options.headers || {};

  const fetchOptions = {
    ...options,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    } as any,
  };

  if (jwtToken) {
    fetchOptions.headers['X-NotesToken'] = jwtToken;
  } else if (authToken) {
    fetchOptions.headers.Authorization = `Bearer ${authToken}`;
  }

  return fetchJson<T>(url, fetchOptions);
};
